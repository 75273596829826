export default {
    validations: {
        required: 'Camp obligatoriu',
        requiredUnless: 'Campul este dependent de',
        minLength: 'Campul trebuie sa contina minim {min} caractere',
        maxLength: 'Campul trebuie sa contina maxim {max} caractere',
        email: 'Camp invalid',
        phoneFormat: 'Campul trebuie sa inceapa cu 07 (ex. 0761234567)',
        cifFormat: 'Camp invalid',
        numeric: 'Campul trebuie sa contina 10 cifre',
        alphaNumUnderscoreDash: 'Campul poate contine doar caractere alfanumerice sau _ -',
        alphaNumDash: 'Campul poate contine doar caractere alfanumerice sau -',
        alphaNumUnderscore: 'Campul poate contine doar caractere alfanumerice sau _',
        alphaNum: 'Campul poate contine doar caractere alfanumerice',
        alphaNumSpace: 'Campul poate contine doar caractere alfanumerice si spatiu',
        password: 'Campul trebuie sa contina minimum 6 caractere, o litera mare sau o cifra, o litera mica',
        export: 'Campul trebuie sa fie selectat / bifat',
        companyEmailAvability: 'Adresa de email apartine unui cont existent',
        companySubdomainAvability: 'Subdomaniul apartine unui cont existent',
        companyPhoneAvability: 'Numarul de telefon apartine unui cont existent',
        companyVatAvability: 'CIF-ul apartine unui cont existent',
        ibanFormat: 'IBAN invalid',
        duplicateAccountEmail: 'Adresa de email este folosita la un alt cont',
        duplicateAccountIBAN: 'IBAN-ul este folosit la un alt cont',
        duplicateAccountUsername: 'Numele nu este disponibil',
        alpha: 'Campul trebuie sa contina doar o litera',
        alphaDash: 'Campul poate contine doar litere si spatiu',
        formCNPDuplicate: 'Acest CNP este deja inregistrat pentru anul curent',
        formPhoneDuplicate: 'Detinatorul acestui numar de telefon a redirectionat deja pentru anul curent',
        cnpFormat: 'CNP-ul nu este valid',
    },
};
