<template>
    <div class="grid col-12 justify-content-center align-items-start mt-5 px-0">
        <template v-if="isLoading">
            <div
                class="px-3 py-0"
                :class="{
                    'col-12': isMD,
                    'col-5': !isMD,
                    'p-1': isMD,
                }"
            >
                <Skeleton height="30rem"></Skeleton>
            </div>
        </template>
        <template v-else>
            <InfoComponent
                class="px-3 py-0 row-gap-6"
                :class="{
                    'col-12': isMD,
                    'col-5': !isMD,
                    'p-1': isMD,
                }"
            ></InfoComponent>
        </template>
        <template v-if="isLoading">
            <div
                class="col-fixed px-3 py-0"
                :class="{
                    'col-12': isMD,
                    'col-7': !isMD,
                    'mt-6': isMD,
                    'p-1': isMD,
                }"
            >
                <Skeleton height="50rem"></Skeleton>
            </div>
        </template>
        <template v-else>
            <ProductsComponent
                class="col-fixed px-3 py-0"
                :class="{
                    'col-12': isMD,
                    'col-7': !isMD,
                    'mt-6': isMD,
                    'p-1': isMD,
                }"
            ></ProductsComponent>
        </template>
        <v-tour
            v-if="!isLoading"
            name="pageTour"
            v-model:steps="steps"
            :options="tourOptions"
            :callbacks="tourCallbacks"
        ></v-tour>
    </div>
</template>
<script lang="ts">
import { computed, defineAsyncComponent, defineComponent, getCurrentInstance, onMounted, Ref, ref, watch } from 'vue';
import { useScreen, useGrid } from 'vue-screen';
const InfoComponent = import('./Info.vue');
const ProductsComponent = import('./Products.vue');

import feathersClient from '@/feathers-client';
import { useAuth } from '@/composables/use-auth';
import { useStore } from 'vuex';
import { useCookies } from 'vue3-cookies';
import { useLogger } from '@/composables/use-logger';

const tourOptions = {
    labels: {
        buttonSkip: 'Inchide',
        buttonPrevious: 'Anterior',
        buttonNext: 'Urmatorul',
        buttonStop: 'Inchide',
    },
    startTimeout: 800,
    highlight: true,
    enableScrolling: false,
};

export default defineComponent({
    name: 'OrderInfo',
    components: {
        InfoComponent: defineAsyncComponent(() => Promise.resolve(InfoComponent)),
        ProductsComponent: defineAsyncComponent(() => Promise.resolve(ProductsComponent)),
    },
    setup() {
        const OrdersAPI = feathersClient.service('/orders');
        const logger = useLogger();
        const store = useStore();
        const auth = useAuth(store);
        const { cookies } = useCookies();
        const isLoading = ref<boolean>(false);
        const screen = useScreen();
        const grid = useGrid({
            a: 300,
            b: 500,
            c: 700,
            d: 900,
            e: 1200,
            f: 1500,
        });

        const tours: Ref<any> = ref();

        const setupTour = () => {
            if (typeof tours.value['pageTour'] === 'undefined') {
                return false;
            }
            tours.value['pageTour'].start();
        };

        const user: any = computed(() => store.getters['auth/user']);
        const tourReady = computed(() => store.getters['app/orderTourReady']);
        const tourStarted = computed(() => store.getters['app/orderTourStarted']);
        const steps = computed(() => store.getters['app/orderTourSteps']);
        const orderLoaded = computed(() => store.getters['orders/reference'] != null);

        const completeTour = () => {
            cookies.set('ls-tour', '1', '7d');
        };

        const tourCallbacks = {
            onSkip: completeTour,
            onFinish: completeTour,
            onStop: completeTour,
        };

        watch(tourReady, (status: boolean) => {
            if (status && !tourStarted.value && !cookies.isKey('ls-tour')) {
                console.debug('tour ready');
                setupTour();
                store.commit('app/SET_TOUR_STARTED', true);
            }
        });

        const isMD = computed(() => screen.width <= 1160);

        // useKsconnectWatcher();

        onMounted(async () => {
            const internalInstance = getCurrentInstance();
            tours.value = internalInstance?.appContext.config.globalProperties.$tours;
            try {
                isLoading.value = true;
                logger.info('Setup KSConnect');
                await store.dispatch('ksconnect/Setup').catch(console.error);

                const order = await OrdersAPI.get(user.value.orderId);
                store.commit('orders/SetOrderData', order);
            } catch (err) {
                return auth.logout();
            } finally {
                isLoading.value = false;
            }
        });

        return { steps, tourOptions, tourCallbacks, orderLoaded, isLoading, isMD, grid };
    },
});
</script>
<style></style>
