import { ProductKey, ProductTypes } from '@/store/ksconnect';
import axios from 'axios';

export interface StartInstallerPayload {
    odtId: string;
    uninstallPreviousVersions: '1' | '0';
    lang: string;
}

export interface RemoveInstallFilesPayload {
    odtId: string;
    lang: string;
}

export interface ServiceApi {
    setAuthToken: (token: string) => void;
    fetchHealth: () => Promise<any>;
    fetchInfo: () => Promise<any>;
    getProductKeys: (productType: ProductTypes) => Promise<ProductKey[]>;
    getInstalledProducts: (productName: string) => Promise<string[]>;
    upgradeWindows: (from: string, to: string) => Promise<any>;
    cleanInstalledKeys: (productType: ProductTypes, includeActiveKeys: '1' | '0') => Promise<any>;
    installProductKey: (productKey: string) => Promise<any>;
    activateKeyOnline: (productKey: string) => Promise<any>;
    activateKeyAlternative: (productKey: string, cid: string) => Promise<any>;
    startInstaller: (data: StartInstallerPayload) => Promise<any>;
    checkInstallerProgress: () => Promise<any>;
    removeInstallFiles: (data: RemoveInstallFilesPayload) => Promise<any>;
}
export const useKSConnect = (serviceUrl: string): (() => ServiceApi) => {
    const ENDPOINTS = {
        health: '/health',
        info: '/info',
        getProductKeys: '/api/keys',
        getInstalledProducts: '/api/installedproducts',
        upgradeWindows: '/api/upgradewindows',
        cleanInstalledKeys: '/api/cleaninstalledkeys',
        installProductKey: '/api/installproductkey',
        activateKeyOnline: '/api/activatekeyonline',
        activateKeyAlternative: '/api/activatekeyalternative',
        startInstaller: '/api/install',
        checkInstallerProgress: '/api/installprogress',
        removeInstallFiles: '/api/removeinstallfiles',
    };

    const axiosService = axios.create({
        baseURL: serviceUrl,
        timeout: 180_000, // 3 minutes
    });

    let authToken: string | null = null;

    const parseResponse = (response: any) => {
        return response.data;
    };

    const authHeader = () => {
        return {
            Authorization: `Bearer ${authToken}`,
        };
    };

    const api = (): ServiceApi => ({
        setAuthToken: (token: string) => {
            authToken = token;
        },
        fetchHealth: async () => {
            return await axiosService.get(ENDPOINTS.health).then(parseResponse);
        },
        fetchInfo: async () => {
            return await axiosService.get(ENDPOINTS.info).then(parseResponse);
        },
        getProductKeys: async (productType) => {
            return await axiosService
                .get(ENDPOINTS.getProductKeys, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                    params: {
                        product: productType,
                    },
                })
                .then(parseResponse);
        },
        getInstalledProducts: async (productName: string): Promise<string[]> => {
            return await axiosService
                .get(ENDPOINTS.getInstalledProducts, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                    params: {
                        product: productName,
                    },
                })
                .then(parseResponse);
        },
        upgradeWindows: async (from: string, to: string) => {
            return await axiosService
                .post(
                    ENDPOINTS.upgradeWindows,
                    {
                        from,
                        to,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                    }
                )
                .then(parseResponse);
        },
        cleanInstalledKeys: async (productType: ProductTypes, includeActiveKeys) => {
            return await axiosService
                .post(
                    ENDPOINTS.cleanInstalledKeys,
                    {
                        type: productType,
                        includeActiveKeys,
                    },
                    {
                        headers: { ...authHeader() },
                    }
                )
                .then(parseResponse);
        },
        installProductKey: async (productKey: string) => {
            return await axiosService
                .post(
                    ENDPOINTS.installProductKey,
                    {
                        key: productKey,
                    },
                    {
                        headers: { ...authHeader() },
                    }
                )
                .then(parseResponse);
        },
        activateKeyOnline: async (productKey: string) => {
            return await axiosService
                .post(
                    ENDPOINTS.activateKeyOnline,
                    {
                        key: productKey,
                    },
                    {
                        headers: { ...authHeader() },
                    }
                )
                .then(parseResponse);
        },
        activateKeyAlternative: async (productKey, cid: string) => {
            return await axiosService
                .post(
                    ENDPOINTS.activateKeyAlternative,
                    {
                        key: productKey,
                        cid,
                    },
                    {
                        headers: { ...authHeader() },
                    }
                )
                .then(parseResponse);
        },
        startInstaller: async (data: StartInstallerPayload) => {
            return await axiosService
                .post(ENDPOINTS.startInstaller, data, {
                    headers: { ...authHeader() },
                })
                .then(parseResponse);
        },
        checkInstallerProgress: async () => {
            return await axiosService
                .get(ENDPOINTS.checkInstallerProgress, {
                    headers: { ...authHeader() },
                })
                .then(parseResponse);
        },
        removeInstallFiles: async (data: RemoveInstallFilesPayload) => {
            return await axiosService
                .post(ENDPOINTS.removeInstallFiles, data, {
                    headers: { ...authHeader() },
                })
                .then(parseResponse);
        },
    });

    return api;
};
