<template>
    <div
        class="flex flex-row justify-content-start align-items-center gap-2"
        :class="{
            'text-green-500': productCompatible,
            'text-red-500': !productCompatible,
        }"
    >
        <i
            :class="{
                pi: true,
                'pi-check': productCompatible,
                'pi-times': !productCompatible,
            }"
        ></i>
        <template v-if="productCompatible">
            <span>Produsul este compatibil cu dispozitivul</span>
        </template>
        <template v-else>
            <div>
                Produsul nu este compatibil cu dispozitivul.
                <span @click="openCompatibilityDialog" class="font-smibold underline cursor-pointer">Detalii</span>
            </div>
        </template>
        <CompatibilityDialog
            v-if="compatibilityDialogOpened"
            @hide="closeCompatibilityDialog"
            :pid="pid"
        ></CompatibilityDialog>
    </div>
</template>

<script setup lang="ts">
import { useProductCompatibilityStatus } from '@/composables/ksconnect/use-product-compatibility-status';
import CompatibilityDialog from '@/components/CompatibilityDialog/Index.vue';
import { ref, toRefs } from 'vue';

const props = defineProps<{
    pid: number;
}>();

const { pid } = toRefs(props);
const compatibilityDialogOpened = ref<boolean>(false);

const openCompatibilityDialog = () => {
    compatibilityDialogOpened.value = true;
};

const closeCompatibilityDialog = () => {
    compatibilityDialogOpened.value = false;
};

const { productCompatible } = useProductCompatibilityStatus(pid.value);
</script>

<style scoped></style>
