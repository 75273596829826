<template>
    <div class="layout flex flex-column w-full bg-gray-200 h-full">
        <div class="underlay flex flex-column w-full align-items-center justify-content-center">
            <CodeInput :uid="uid"></CodeInput>
            <KSConnectBanner v-if="displayKSConnectBanner" />
            <KSConnectActivationWizardBanner v-if="displayKSConnectActivationWizardBanner" />
            <component v-if="!isLoading" :uid="uid" :is="activeComponent" />
            <Loading :active="isLoading" :lock-scroll="true" :is-full-page="true" />
        </div>
    </div>
</template>
<script setup lang="ts">
import { useAuth } from '@/composables/use-auth';
import { useEnv } from '@/composables/use-env';
import { computed, onMounted, Ref, ref } from 'vue';

import CodeInput from '@components/CodeInput/Index.vue';
import OrderInfo from '@components/OrderInfo/Index.vue';
import OrderPlaceholder from '@components/OrderPlaceholder/Index.vue';
import KSConnectBanner from '@components/KSConnectBanner/Full.vue';
import TosConfirm from '@components/TosConfirm/Index.vue';
import KSConnectActivationWizardBanner from '@components/KSConnectBanner/ActivationWizard.vue';

import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useLogger } from '@/composables/use-logger';

const route = useRoute();
const store = useStore();
const auth = useAuth(store);
const env = useEnv();
const isLoading: Ref<boolean> = ref(false);
const logger = useLogger();

const pageComponents = {
    OrderInfo,
    OrderPlaceholder,
    TosConfirm,
};

const uid: any = route.params.uid;
const activeComponent = computed(() => {
    if (store.getters['auth/isAuthenticated']) {
        if (!store.getters['auth/hasToSConfirmed']) {
            return TosConfirm;
        }
        return OrderInfo;
    }
    return OrderPlaceholder;
});

const displayKSConnectBanner = computed(
    () =>
        store.getters['ksconnect/deviceCompatibleWithKSConnect'] &&
        (!store.getters['ksconnect/serviceEnabled'] || !store.getters['ksconnect/appIsLatestVersion']) &&
        store.getters['orders/isLoaded']
);

const displayKSConnectActivationWizardBanner = computed(
    () =>
        store.getters['ksconnect/deviceCompatibleWithKSConnect'] &&
        store.getters['ksconnect/serviceEnabled'] &&
        store.getters['ksconnect/appIsLatestVersion'] &&
        store.getters['ksconnect/productsCompatible']
);

onMounted(async () => {
    await store.dispatch('app/GetFreeDays').catch((err: any) => {});
    const jwtKey = env.get('JWT_LS_NAME') || 'koutletcontrol';
    if (localStorage.getItem(jwtKey)) {
        isLoading.value = true;
        try {
            const result = await auth.login();
            if (uid && result.orders_control?.uid && uid != result.orders_control?.uid) {
                return auth.logout().finally(() => (isLoading.value = false));
            }
            isLoading.value = false;
        } catch (err) {
            return auth.logout().then(() => (isLoading.value = false));
        }
    }
});
</script>
<style lang="scss">
.underlay {
    top: -23rem;
    display: block;
    position: relative;
}

.layout {
    min-height: 100vh;
}
</style>
