<template>
    <Dialog visible modal :closable="false" class="col-10 md:col-6">
        <template #header>
            <span></span>
        </template>
        <template v-if="downloadStarted">
            <div class="flex flex-column align-items-center justify-content-center p-5 gap-5">
                <div class="text-2xl flex flex-column align-items-center gap-3">
                    <div class="flex flex-row justify-content-center gap-2">
                        <div>
                            <i class="pi pi-download text-3xl"></i>
                        </div>
                        <div class="font-semibold">Descarcarea va incepe in cateva secunde...</div>
                    </div>
                    <div class="text-sm">
                        Daca descarcarea nu incepe automat,
                        <a :href="downloadLink" target="_blank" class="p-button p-button-link p-0 inline text-sm font-semibold"
                            >click aici</a
                        >
                    </div>
                    <div>
                        <Button severity="primary" class="mt-3" @click="close">Am descarcat si rulat aplicatia</Button>
                    </div>
                    <InlineMessage severity="info"
                        >Dupa descarcarea si rularea aplicatiei, efectueaza click pe butonul de mai sus pentru a reincarca
                        pagina.</InlineMessage
                    >

                    <div class="text-base surface-200 p-3 border-round mt-3">
                        <div class="text-center flex flex-row justify-content-center align-items-center border-1 border-400 mb-3">
                            <img src="https://cdn1.keysoutlet.ro/data/assets/img/ksconnect_firewall_exception.png?v=101" alt="" />
                        </div>
                        <div>Cand apare o fereastra similara cu cea din imaginea de mai sus, urmati acesti pasi:</div>
                        <ul>
                            <li>Asigurati-va că optiunea "Private networks, such as my home or work network" este bifata.</li>
                            <li>Apoi, faceti click pe "Allow access"</li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="flex flex-column justify-content-center align-items-start gap-4 w-full">
                <div class="w-full px-6 flex flex-column align-items-start justify-content-center">
                    <div class="mb-3">Alege KSConnect pentru:</div>
                    <ul class="m-0 line-height-3">
                        <li>Gestionarea eficienta a licentelor pe dispozitiv.</li>
                        <li>Activarea rapida si usoara a licentelor.</li>
                        <li>Verificarea compatibilitatii produselor cu sistemul de operare.</li>
                        <li>Raportare automata a licentelor invalide si schimbarea lor in timp record.</li>
                    </ul>
                </div>
                <div class="flex flex-column justify-content-center align-items-center gap-4">
                    <div class="flex align-items-start cursor-pointer px-6 line-height-2">
                        <Checkbox v-model="tosAgreed" inputId="tosAgreed" name="tosAgreed" :value="1" />
                        <label for="tosAgreed" class="ml-2 cursor-pointer line-height-2">
                            Prin descarcarea si utilizarea aplicatiei KSConnect sunt de acord cu
                            <a href="/ksconnect-tos" class="font-semibold underline font-italic" target="_blank"
                                >Termenii si conditiile</a
                            >
                            si
                            <a href="ksconnect-gdpr" class="font-semibold underline font-italic" target="_blank"
                                >Politica de confidentialitate</a
                            >
                        </label>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <Button text label="Inchide" severity="secondary" @click="close"></Button>

            <Button
                v-if="!downloadStarted"
                :disabled="!tosAgreedComputed"
                severity="primary"
                class="download-btn"
                @click="downloadApp"
                >Descarca aplicatia KSConnect</Button
            >
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { defineEmits } from 'vue';
import { useKSConnectDownload } from '@/composables/ksconnect/use-download';
import InlineMessage from 'primevue/inlinemessage';

const emit = defineEmits<{
    (e: 'close', val: boolean): void;
}>();

const close = () => {
    emit('close', downloadStarted.value);
};

const { downloadApp, downloadLink, tosAgreed, downloadStarted, tosAgreedComputed } = useKSConnectDownload();
</script>

<style scoped>
.download-btn {
    padding: 0.8rem 1.2rem;
}
</style>
