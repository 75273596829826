<template>
    <div class="ks-connect-full-banner input-container mb-6 sm:w-9 w-11">
        <div class="flex flex-row align-items-center justify-content-between">
            <img src="/images/ksconnect_logo_full.png" class="ks-connect-logo" alt="KS Connect logo" />
            <div class="text-white text-lg font-semibold">Am identificat produse compatibile cu acest dispozitv.</div>

            <Button
                label="Activeaza produsele"
                class="shadow-1 p-button-primary text-bluegray-800 p-button-size-medium"
                icon="pi pi-bolt"
                @click="openActivationWizard"
            />
        </div>
        <ActivationWizard v-if="isActivationWizardOpened" />
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';

import ActivationWizard from '@/components/KSConnect/ActivationWizard/ActivationWizard.vue';

const store = useStore();

const isActivationWizardOpened = computed(() => store.getters['ksconnect/activationWizardLaunched']);

const openActivationWizard = () => {
    store.commit('ksconnect/LaunchActivationWizard');
};
</script>

<style lang="scss" scoped>
.ks-connect-full-banner {
    background-color: hsl(0deg 0% 100% / 9%);
    border-radius: 3px;
    backdrop-filter: blur(1px);
    padding: 1.2rem;
}

img.ks-connect-logo {
    width: 100%;
    width: 11rem;
}
</style>
