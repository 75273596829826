import { ProductInstalledStatuses } from '@/store/ksconnect';

export const findInstalledApp = (installedProducts: string[], productCodeName: string): string | undefined => {
    const appMatchers: Record<string, RegExp> = {
        OFFICE21PP: /Office.*Professional\sPlus\s2021/,
        OFFICE19PP: /Office.*Professional\sPlus\s2019/,
        OFFICE19PRO: /Office.*Professional\s2019/,
        OFFICE16PP: /Office.*Professional\sPlus\s2016/,
        OFFICE13PP: /Office.*Professional\sPlus\s2013/,
        OFFICE10PP: /Office.*Professional\sPlus\s2010/,
    };

    if (productCodeName in appMatchers) {
        const regex = appMatchers[productCodeName];
        return regex ? installedProducts.find((app) => regex.test(app)) : undefined;
    }
    return undefined;
};

export const getInstallationStatus = (
    installedApp: string | undefined,
    installedProducts: string[]
): ProductInstalledStatuses => {
    if (!installedApp) {
        return ProductInstalledStatuses.OTHERS_INSTALLED;
    }
    if (installedProducts.length === 1) {
        return ProductInstalledStatuses.INSTALLED;
    }
    return ProductInstalledStatuses.INSTALLED_WITH_OTHERS;
};
