<template>
    <span class="p-text-secondary text-lg block mb-3">Alege produsul pe care doresti sa il activezi:</span>
    <div class="flex flex-column w-full align-items-start justify-content-start">
        <template v-if="products == null || products.length == 0">
            <Message class="w-full" severity="warn"
                >Nu exista produse compatibile sau disponibile pentru activare pe acest dispozitiv.</Message
            >
        </template>
        <template v-else>
            <div class="w-full flex flex-column justify-content-center align-items-center gap-5">
                <!-- Available Products List -->
                <div class="flex flex-column gap-4 w-full" v-if="availableProducts.length > 0">
                    <h3 class="mb-0">Produse disponibile:</h3>
                    <div class="available-products-container flex flex-column gap-4">
                        <div
                            v-for="(p, _) in availableProducts"
                            :key="p.pid"
                            class="flex flex-row align-items-center justify-content-start available-product border-1 py-2 pl-3 pr-5 border-round cursor-pointer"
                            :class="{
                                'border-1': product != p.pid,
                                'border-2': product == p.pid,
                                'surface-border': product != p.pid,
                                'border-primary': product == p.pid,
                            }"
                        >
                            <RadioButton v-model="product" :inputId="String(p.pid)" :name="String(p.pid)" :value="p.pid" />
                            <label
                                :for="String(p.pid)"
                                class="w-full ml-2 flex flex-row align-items-center justify-content-start cursor-pointer"
                            >
                                <img width="100px" class="product-image mr-3" :src="p.image" alt="" />
                                <div class="flex flex-column justify-content-center align-items-start gap-2">
                                    <span class="font-semibold text-base">{{ p.name }}</span>
                                    <div class="text-sm flex flex-column justify-content-center align-items-start gap-1">
                                        <ChecksProductCompatible :pid="p.pid"></ChecksProductCompatible>
                                        <ChecksProductInstalled
                                            :pid="p.pid"
                                            v-if="!productTypeIsWindows(p)"
                                        ></ChecksProductInstalled>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                <!-- Unavailable Products List -->
                <div class="flex flex-column gap-4 w-full" v-if="unavailableProducts.length > 0">
                    <h3 class="mb-0">Produse indisponibile:</h3>
                    <div class="unavailable-products-container flex flex-column justify-content-center align-items-center gap-4">
                        <div
                            v-for="(p, _) in unavailableProducts"
                            :key="p.pid"
                            class="flex flex-row align-items-center justify-content-start unavailable-product border-1 py-2 pl-3 pr-5 border-round border-red-500"
                        >
                            <div class="w-full ml-2 flex flex-row align-items-center justify-content-start">
                                <img width="100px" class="product-image" :src="p.image" alt="" />
                                <div class="flex flex-column justify-content-center align-items-start gap-2">
                                    <span class="font-semibold text-base">{{ p.name }}</span>
                                    <div class="text-sm flex flex-column justify-content-center align-items-start gap-1">
                                        <ChecksProductCompatible :pid="p.pid"></ChecksProductCompatible>
                                        <ChecksProductInstalled
                                            :pid="p.pid"
                                            v-if="!productTypeIsWindows(p)"
                                        ></ChecksProductInstalled>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import { ProductReadyForActivation, ProductTypes } from '@/store/ksconnect';
import { computed } from 'vue';
import { useStore } from 'vuex';
import ChecksProductInstalled from '@/components/KSConnect/Checks/ProductInstalled.vue';
import ChecksProductCompatible from '@/components/KSConnect/Checks/ProductCompatible.vue';
import { useProductInstallationStatus } from '@/composables/ksconnect/use-product-installation-status';
import { useProductCompatibilityStatus } from '@/composables/ksconnect/use-product-compatibility-status';
import InlineMessage from 'primevue/inlinemessage';

const store = useStore();

const productTypeIsWindows = (product: ProductReadyForActivation) => {
    return product.type === ProductTypes.WINDOWS;
};
const productInstalled = (product: ProductReadyForActivation) => {
    const { isProductInstalled, isProductInstalledWithOthers } = useProductInstallationStatus(product.pid);
    return isProductInstalled.value || isProductInstalledWithOthers.value;
};

const productIsCompatible = (product: ProductReadyForActivation) => {
    const { productCompatible } = useProductCompatibilityStatus(product.pid);
    return productCompatible.value;
};

const product = computed({
    get() {
        return store.getters['ksconnect/activationWizardSelectedPid'];
    },
    set(value: any) {
        store.commit('ksconnect/SetActivationWizardPid', value);
    },
});

const products = computed<ProductReadyForActivation[]>(() => {
    return store.getters['ksconnect/productsReadyForActivation'];
});

// Computed lists for available and unavailable products
const availableProducts = computed(() =>
    products.value.filter((p) => productIsCompatible(p) && (productTypeIsWindows(p) || productInstalled(p)))
);
const unavailableProducts = computed(() =>
    products.value.filter((p) => !productIsCompatible(p) || (!productInstalled(p) && !productTypeIsWindows(p)))
);
</script>

<style scoped>
.available-product,
.unavailable-product {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    width: calc(100% - 20px);

    .product-image {
        width: 100px;
    }
}

.available-products-container,
.unavailable-products-container {
    max-height: 500px;
    overflow-y: auto;
}
</style>
