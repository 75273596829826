<template>
    <div
        class="flex flex-row justify-content-start align-items-center gap-2"
        :class="{
            'text-green-500': isProductInstalled,
            'text-orange-500': isProductInstalledWithOthers || (isProductNotInstalled && canBeInstalled),
            'text-red-500': otherProductsInstalled || (isProductNotInstalled && !canBeInstalled),
        }"
    >
        <i
            :class="{
                pi: true,
                'pi-check': isProductInstalled,
                'pi-exclamation-circle': isProductInstalledWithOthers,
                'pi-times': !isProductInstalled && !isProductInstalledWithOthers,
            }"
        ></i>
        <template v-if="isProductInstalled">
            <span>Produsul este instalat pe dispozitiv</span>
        </template>
        <template v-else-if="isProductInstalledWithOthers">
            <div>
                Produsul este instalat impreuna cu alte versiuni.
                <span class="font-semibold underline cursor-pointer" @click="openProductInstallationDialog">Detalii</span>
            </div>
        </template>
        <template v-else-if="otherProductsInstalled">
            <div>
                Alte versiuni ale produsului sunt instalate pe acest dispozitiv.
                <span class="font-semibold underline cursor-pointer" @click="openProductInstallationDialog">Detalii</span>
            </div>
        </template>
        <template v-else-if="isProductNotInstalled && canBeInstalled">
            <div>
                Produsul nu este instalat pe dispozitiv.
                <span v-if="canBeInstalled" class="font-semibold underline cursor-pointer" @click="openInstallerDialog"
                    >Instaleaza</span
                >
            </div>
        </template>
        <template v-else>Produsul nu este instalat pe dispozitiv</template>

        <ProductInstallationDialog
            v-if="isProductInstallationDialogVisible"
            @hide="closeProductInstallationDialog"
            :pid="pid"
        ></ProductInstallationDialog>
        <ProductInstallerDialog
            v-if="isProductInstallerDialogVisible"
            @close="closeInstallerDialog"
            :pid="pid"
        ></ProductInstallerDialog>
    </div>
</template>

<script setup lang="ts">
import { ref, defineProps, toRefs } from 'vue';
import { useProductInstallationStatus } from '@/composables/ksconnect/use-product-installation-status';
import ProductInstallationDialog from '@/components/ProductInstallationDialog/Index.vue';
import ProductInstallerDialog from '@/components/KSConnect/Checks/ProductInstallerDialog.vue';
import { useInstaller } from '@/composables/ksconnect/use-installer';

const props = defineProps<{
    pid: number;
}>();

const { pid } = toRefs(props);

const isProductInstallationDialogVisible = ref<boolean>(false);

const openProductInstallationDialog = () => {
    isProductInstallationDialogVisible.value = true;
};

const closeProductInstallationDialog = () => {
    isProductInstallationDialogVisible.value = false;
};

const { isProductInstalled, isProductInstalledWithOthers, otherProductsInstalled, isProductNotInstalled } =
    useProductInstallationStatus(pid.value);
const { openInstallerDialog, closeInstallerDialog, isProductInstallerDialogVisible, canBeInstalled } = useInstaller(pid.value);
</script>

<style scoped></style>
