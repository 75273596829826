import { useKSConnect } from '@/composables/ksconnect/use-ksconnect';
import { InstallerLanguage, InstallerSteps, KSConnect, KSConnectInfo, ProductAvailable, ProductKey, ProductTypes } from '.';
import { useLogger } from '@/composables/use-logger';
import { activationWizardState, installerState, windowsUpgradeState } from './state';

const logger = useLogger();

export default {
    SetUrl(state: KSConnect, url: string) {
        state.serviceUrl = url;
    },
    SetEnabled(state: KSConnect, enabled: boolean) {
        state.enabled = enabled;
    },
    SetServiceError: (state: KSConnect, error: string) => {
        console.log('SetServiceError', error);
        if (state.error == null) {
            state.error = error;
        }
    },

    SetServiceApi(state: KSConnect) {
        if (state.serviceUrl == null) {
            return;
        }
        const ksConnectService = useKSConnect(state.serviceUrl)();
        state.serviceApi = ksConnectService;
    },
    SetInfo(state: KSConnect, data: { data: KSConnectInfo; app_version: string }) {
        const { data: info, app_version } = data;
        logger.info('Setting KSConnect info:', info);
        try {
            info.hostname = atob(info.hostname);
        } catch (err: any) {
            info.hostname = 'UNKNOWN';
        }
        state.info = {
            hostname: info.hostname,
            os_build: info.os_build,
            os_caption: info.os_caption,
            os_version: info.os_version,
            uid: info.uid,
        };
        state.installedAppVersion = app_version;
    },
    SetHasRating: (state: KSConnect, hasRating: boolean) => {
        state.hasRating = hasRating;
    },
    SetAppVersion: (state: KSConnect, version: string) => {
        state.appVersion = version;
    },
    SetSetupCompleted(state: KSConnect, complete: boolean) {
        state.setupComplete = complete;
    },
    SetDeviceCompatible(state: KSConnect, compatible: boolean) {
        state.deviceCompatible = compatible;
    },
    AddAvailableProduct: (state: KSConnect, product: ProductAvailable) => {
        state.productsAvailable.push(product);
        if (product.isCompatibleWithDevice) {
            state.productsCompatible = true;
        }
    },
    SetInstalledProducts: (
        state: KSConnect,
        { product, products }: { product: 'OFFICE' | 'VISIO' | 'PROJECT'; products: string[] }
    ) => {
        if (typeof state.installedProducts[product] === 'undefined' || products == null) {
            return;
        }
        const filteredProducts = products.filter((p: string) => {
            return state.installedProducts[product].filterRegex.test(p);
        });
        state.installedProducts[product].list = filteredProducts;
        state.installedProducts[product].productsFetched = true;
        return filteredProducts;
    },
    SetInstalledProductKeys: (
        state: KSConnect,
        { productType, keys }: { productType: Exclude<ProductTypes, ProductTypes.WINDOWS>; keys: ProductKey[] }
    ) => {
        if (!Array.isArray(keys)) {
            logger.error('Keys is not an array:', keys);
            return;
        }
        // Clear all keys for this product
        state.installedKeys = state.installedKeys.filter((key) => key.product !== productType);

        state.installedKeys.push(...keys.map((key) => ({ ...key, product: productType })));
    },
    SetWindowsUpgradeWindowActive: (state: KSConnect, open: boolean) => {
        state.windowsUpgrade.windowActive = open;
    },
    LaunchActivationWizard: (state: KSConnect) => {
        state.activationWizard.launched = true;
    },
    CloseActivationWizard: (state: KSConnect) => {
        logger.info('Closing activation wizard');
        state.activationWizard = activationWizardState();
        state.windowsUpgrade = windowsUpgradeState();
    },
    SetActivationWizardPid: (state: KSConnect, pid: number) => {
        state.activationWizard.selectedPid = pid;
    },
    SetActivationWizardNextStep: (state: KSConnect, step?: 1 | 2 | 3 | 4) => {
        if (step) {
            state.activationWizard.step = step;
            return;
        }
        state.activationWizard.step++;
    },
    SetActivationWizardKey: (state: KSConnect, key: number) => {
        state.activationWizard.selectedKey = key;
    },
    SetActivationRunning: (state: KSConnect, running: boolean) => {
        state.activationWizard.activationRunning = running;
    },
    SetUninstallActiveKeys: (state: KSConnect, uninstall: '1' | '0') => {
        state.activationWizard.uninstallActiveKeys = uninstall;
    },
    SetActivationProgressStep: (state: KSConnect, step: number) => {
        const stepIndex = state.activationWizard.activationProgress.findIndex((s) => s.step == step);
        if (stepIndex == -1) {
            logger.error('Step not found in activation progress:', step);
            return;
        }
        // Replace the object in the array with a new object reference
        const updatedStep = {
            ...state.activationWizard.activationProgress[stepIndex], // Spread the original object
            started: true, // Update the started property
        };

        // Replace the item in the array to trigger reactivity
        state.activationWizard.activationProgress.splice(stepIndex, 1, updatedStep);
    },
    UpdateActivationStep: (state: KSConnect, { step, name, ...data }: { step?: number; name?: string }) => {
        const stepIndex = state.activationWizard.activationProgress.findIndex((s) => s.step == step || s.name == name);
        if (stepIndex == -1) {
            logger.error('Step not found in activation progress:', step);
            return;
        }
        // Replace the object in the array with a new object reference
        const updatedStep = {
            ...state.activationWizard.activationProgress[stepIndex], // Spread the original object
            ...data, // Update the started property
        };
        // Replace the item in the array to trigger reactivity
        state.activationWizard.activationProgress.splice(stepIndex, 1, updatedStep);
    },
    SetActivationWizardReloadOnClose: (state: KSConnect, reload: boolean) => {
        state.activationWizard.reloadOnClose = reload;
    },
    SetWindowsUpgradeRunning: (state: KSConnect, running: boolean) => {
        state.windowsUpgrade.upgradeRunning = running;
    },
    SetWindowsUpgradeError: (state: KSConnect, error: string | null) => {
        state.windowsUpgrade.upgradeError = error;
    },
    SetInstallerRunning: (state: KSConnect, running: boolean) => {
        state.installer.running = running;
    },
    SetInstallSetupPath: (state: KSConnect, path: string) => {
        state.installer.setupPath = path;
    },
    SetInstallerError: (state: KSConnect, error: string | null) => {
        state.installer.error = error;
    },
    SetInstallerStep: (state: KSConnect, step: InstallerSteps) => {
        state.installer.step = step;
    },
    SetInstallerProgress: (state: KSConnect, progress: number | null) => {
        state.installer.progress = progress;
    },
    CloseInstaller: (state: KSConnect) => {
        state.installer = installerState();
    },
    SetInstallerUninstallPreviousVersions: (state: KSConnect, value: '1' | '0') => {
        state.installer.uninstallPreviousVersions = value;
    },
    SetInstallerSelectedLanguage: (state: KSConnect, lang: InstallerLanguage) => {
        state.installer.lang = lang;
    },
    SetInstallerTimeProgres: (state: KSConnect, time: number | null) => {
        state.installer.timeProgress = time;
    },
};
