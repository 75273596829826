<template>
    <div class="activation-container">
        <div class="text-center mb-4">
            <h2 class="font-bold text-2xl text-secondary">Activare Produs</h2>
        </div>
        <div class="steps-progress">
            <!-- Divider for current step -->
            <Divider layout="horizontal" class="flex divider" align="center">
                <span class="font-semibold text-secondary">Etapa Curenta</span>
            </Divider>

            <!-- Current Step Component -->
            <div
                class="currentStep text-center the-step p-4 border rounded-lg shadow-md transition-all"
                :class="{
                    'border-red-300 text-red-700': currentActivationStep?.error,
                    'border-green-400 text-green-700': !currentActivationStep?.error,
                    'bg-red-50': currentActivationStep?.error,
                    'bg-green-50': !currentActivationStep?.error,
                }"
            >
                <component :is="currentStepComponent"></component>
            </div>

            <!-- Divider for completed steps -->
            <template v-if="completedSteps.length > 0">
                <Divider layout="horizontal" class="flex divider" align="center">
                    <span class="font-semibold text-secondary">Istoric</span>
                </Divider>

                <!-- Transition for completed steps -->
                <transition-group name="step-transition" tag="div" class="completed-steps flex flex-column gap-3 w-full">
                    <template v-for="step of completedSteps" :key="step.name">
                        <div class="the-step p-3 w-full bg-gray-50 rounded-lg shadow-sm hover:shadow-md transition-shadow">
                            <component :is="stepComponents[step.name]"></component>
                        </div>
                    </template>
                </transition-group>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, onBeforeMount, onMounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const stepComponents: Record<string, any> = {};

const activationProgressSteps = computed(() => store.getters['ksconnect/activationWizardActivationProgress']);
const currentActivationStep = computed(() => store.getters['ksconnect/activationWizardCurrentActivationProgressStep']);
const completedSteps = computed(() => {
    // Sort by step number
    return store.getters['ksconnect/activationWizardActivationProgressCompletedSteps'].sort((a: any, b: any) => b.step - a.step);
});

const startActivation = () => {
    store.commit('ksconnect/SetActivationProgressStep', 1);
};

const currentStepComponent = computed(() => {
    if (!currentActivationStep.value) {
        return null;
    }

    return stepComponents[currentActivationStep.value.name];
});

const setupStepComponents = () => {
    for (const step of activationProgressSteps.value) {
        const component = defineAsyncComponent(() => import(`./ActivationProgress/step_${step.name}.vue`));
        stepComponents[step.name] = component;
    }
};

onMounted(() => {
    startActivation();
});

onBeforeMount(() => {
    setupStepComponents();
    store.commit('ksconnect/SetActivationRunning', true);
});
</script>

<style scoped>
.activation-container {
    min-height: 30rem;
    padding: 0rem 1.5rem 0rem 1.5rem;
}

.divider {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.currentStep.the-step {
    border: 2px solid;
    border-radius: 3px;
    padding: 1.5rem;
    transition: border-color 0.3s ease, background-color 0.3s ease;
    font-size: 1.1rem;
}

.completed-steps .the-step {
    background-color: #f0f4f8;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    padding: 1.2rem;
}

.text-primary {
    color: #007bff;
}

.text-secondary {
    color: #6c757d;
}

/* Transition classes for completed steps */
.step-transition-enter-active,
.step-transition-leave-active {
    transition: transform 0.4s ease, opacity 0.4s ease;
}

.step-transition-enter-from {
    transform: translateY(-10px);
    opacity: 0;
}

.step-transition-enter-to {
    transform: translateY(0);
    opacity: 1;
}

.step-transition-leave-from {
    transform: translateY(0);
    opacity: 1;
}

.step-transition-leave-to {
    transform: translateY(10px);
    opacity: 0;
}
</style>
