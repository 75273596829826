import { useEnv } from '../../composables/use-env';
import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client';
import { ActivationMethods, PID, ProductTypes } from '../ksconnect';

export interface OrderProductKey {
    id: number;
    orders_keys_change_request: { id: number } | null;
    orders_keys_cid: {
        cid: string;
        id: number;
        iid: string;
    } | null;
    orders_keys_device: {
        device: string;
        id: number;
    } | null;
    used_key: {
        id: number;
        cdkey: {
            cdkey: string;
            changedkeys: any[];
            id: number;
        };
        product: {
            activation_methods: ActivationMethods;
            id: PID;
            iidGroupLength: number;
            image: string;
            link: string;
            name: string;
            price: string;
            products_appconfig: {
                canBeActivated: number;
                pType: ProductTypes;
            } | null;
        };
    };
}

export interface OrderProductCompatibility {
    id: number;
    compatibility: {
        codename: string;
        name: string;
    };
}
export interface OrderProductData {
    activation_method: ActivationMethods;
    category: {
        name: string;
    };
    id: number;
    image: string;
    name: string;
    products_appconfig: {
        canBeInstalled: number;
        canBeActivated: number;
        pType?: ProductTypes | '';
        codeName: string;
        productName: string;
        isBinding?: number;
        odtId?: string | null;
    } | null;
    products_compatibilities: OrderProductCompatibility[] | [];
}
export interface OrderProduct {
    data: OrderProductData;
    id: number;
    pid: PID;
    qty: number;
    status: 1 | 0;
    keys: OrderProductKey[];
}

export interface OrderState {
    loaded: boolean;
    orderData: any;
    reference: string | null;
    billing: any;
    changeRequests: any[];
    products: OrderProduct[];
    keys: any;
}

class Orders extends BaseModel {
    constructor(data: any, options: any) {
        super(data, options);
    }
    // Required for $FeathersVuex plugin to work after production transpile.
    static modelName = 'Orders';
    // Define default properties here
    static instanceDefaults() {
        return {};
    }
}

const env = useEnv();

const servicePath = 'orders';
const servicePlugin = makeServicePlugin({
    Model: Orders,
    service: feathersClient.service(servicePath),
    servicePath,
    nameStyle: 'path',
    debug: env.is('NODE_ENV', 'development'),
    idField: 'id',
    enableEvents: true,
    state: {
        loaded: false,
        orderData: {},
        reference: null,
        billing: {},
        changeRequests: [],
        products: [],
        keys: {},
    } as OrderState,
    mutations: {
        SetOrderLoaded: (state: any, data: any) => {
            state.loaded = data;
        },
        SetOrderData: (state: any, data: any) => {
            state.orderData = data;
        },
        SetOrderBilling: (state: any, data: any) => {
            state.billing = data;
        },
        SetInvoiceChangeRequests: (state: any, data: any) => {
            state.changeRequests = data;
        },
        SetOrderProducts: (state: any, data: any) => {
            state.products = data;
        },
        SetProductData: (state: any, data: any) => {
            const productIndex = state.products.findIndex((product: any) => product.id === data.id);
            if (productIndex !== -1) {
                state.products[productIndex].data = data;
            }
        },
        SetProductKeys: (state: any, data: any) => {
            const productIndex = state.products.findIndex((product: any) => product.pid === data.pid);
            if (productIndex !== -1) {
                state.products[productIndex].keys = data.keys;
            }
        },
        SetRemoveKeyDevice: (state: any, data: any) => {
            let productIndex;
            let keyIndex;
            for (const product of state.products) {
                keyIndex = product?.keys?.findIndex((key: any) => key.id === data.keyId);
                if (keyIndex !== -1) {
                    productIndex = state.products.findIndex((p: any) => product.id === p.id);
                    state.products[productIndex].keys[keyIndex].isDeviceRemoving = data.value;
                }
            }
        },
    },
    getters: {
        billing: (state: any) => state.billing,
        reference: (state: any) => state.orderData?.reference,
        display: (state: any) => state.orderData?.display,
        changeRequests: (state: any) => state.changeRequests,
        products: (state: any) => state.products ?? [],
        invoices: (state: any) => state.orderData?.invoices,
        activationWarranty: (state: any) => ({
            activationWarrantyInDays: state?.orderData?.marketplace?.activationWarranty,
            activatinWarrantyExpirationDate: state?.orderData?.marketplace?.warrantyExpirationDate,
            activationWarrantyDaysRemaining: state?.orderData?.marketplace?.activationWarrantyDaysRemaining,
        }),
        isCorder: (state: any) => state.orderData?.corder == 1,
        isReturned: (state: any) => state.orderData?.returned == 1,
        isLoaded: (state: any) => state.loaded,
    },
    actions: {
        SetProductData: async ({ commit }: any, data: any) => {
            commit('SetProductData', data);
        },
    },
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
    before: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
    },
    after: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
    },
    error: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
    },
});

export default servicePlugin;
