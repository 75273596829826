import useStoreModule from '@composables/use-store';
import * as validators from '@vuelidate/validators';
import { createI18n } from 'vue-i18n';
import roLang from '@/lang/ro';
import feathersClient from '@/feathers-client';

const i18n = createI18n({
    locale: 'ro',
    fallbackLocale: 'ro',
    messages: {
        ro: roLang,
    },
});

// or import { createI18nMessage } from '@vuelidate/validators'
const { createI18nMessage } = validators;

// extract the `t` helper, should work for both Vue 2 and Vue 3 versions of vue-i18n
const { t } = i18n.global || i18n;

// pass `t` and create your i18n message instance
const withI18nMessage = createI18nMessage({ t });

export default (queryFilters = {}) => ({
    // wrap each validator.
    required: withI18nMessage(validators.required),
    requiredIf: validators.requiredIf,
    // validators that expect a parameter should have `{ withArguments: true }` passed as a second parameter, to annotate they should be wrapped
    minLength: withI18nMessage(validators.minLength as any, { withArguments: true }) as any,
    // or you can provide the param at definition, statically
    maxLength: withI18nMessage(validators.maxLength as any, { withArguments: true }) as any,

    email: withI18nMessage(validators.email),
    alpha: withI18nMessage(validators.alpha),

    phoneFormat: withI18nMessage({
        $validator: (value: string) => {
            return String(value).substring(0, 2) === '07';
        },
        $params: {
            type: 'phoneFormat',
        },
    }),

    alphaDash: withI18nMessage({
        $validator: (value: string) => {
            return /^[a-zA-ZÀ-ž- ]+$/.test(value);
        },
        $params: {
            type: 'alphaDash',
        },
    }),

    cifFormat: withI18nMessage({
        $validator: (v: string) => {
            if (typeof v !== 'string') {
                return false;
            }
            let cif = v.toUpperCase();
            // remove RO from cif:
            const indexOfRo: number = cif.indexOf('RO');
            if (indexOfRo > -1) {
                cif = cif.substr(0, indexOfRo) + cif.substr(indexOfRo + 2);
            }
            // remove spaces:
            cif = cif.replace(' ', '');
            // validate character length:
            if (cif.length < 2 || cif.length > 10) {
                return false;
            }
            // validate that so far the resulting cif looks like an integer value:
            if (Number.isNaN(parseInt(cif))) {
                return false;
            }
            // begin testing:
            const testKey = '753217532';
            const controlNumber: number = parseInt(cif.substr(cif.length - 1));
            // remove control number:
            cif = cif.substr(0, cif.length - 1);
            // pad left with zeros to reach testKey length:
            while (cif.length !== testKey.length) {
                cif = '0' + cif;
            }
            let sum = 0;
            let i = cif.length;
            while (i--) {
                sum = sum + (cif as any).charAt(i) * (testKey as any).charAt(i);
            }
            let calculatedControlNumber = (sum * 10) % 11;
            if (calculatedControlNumber === 10) {
                calculatedControlNumber = 0;
            }
            return controlNumber === calculatedControlNumber;
        },
        $params: {
            type: 'cifFormat',
        },
    }),

    numeric: withI18nMessage(validators.numeric),

    alphaNum: withI18nMessage(validators.alphaNum),

    alphaNumSpace: withI18nMessage({
        $validator: (v: string) => {
            return /^[a-zA-Z0-9- ]+$/.test(v);
        },
        $params: {
            type: 'alphaNumSpace',
        },
    }),

    alphaNumUnderscoreDash: withI18nMessage({
        $validator: (v: string) => {
            return /^[a-zA-Z0-9-_]+$/.test(v);
        },
        $params: {
            type: 'alphaNumUnderscoreDash',
        },
    }),

    alphaNumDash: withI18nMessage({
        $validator: (v: string) => {
            return /^[a-zA-Z0-9-]+$/.test(v);
        },
        $params: {
            type: 'alphaNumDash',
        },
    }),

    alphaNumUnderscore: withI18nMessage({
        $validator: (v: string) => {
            return /^[a-zA-Z0-9_]+$/.test(v);
        },
        $params: {
            type: 'alphaNumUnderscore',
        },
    }),

    password: withI18nMessage({
        $validator: (v: string) => {
            return /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})./.test(v);
        },
        $params: {
            type: 'password',
        },
    }),

    isTrue: withI18nMessage({
        $validator: (v: string) => !!v,
        $params: {
            type: 'isTrue',
        },
    }),

    companyEmailAvability: (store: any) =>
        withI18nMessage({
            $validator: async (v: string) => {
                if (v === '' || String(v).length == 1) return Promise.resolve(true);
                try {
                    store.commit('company/SetRegistrationEmailStatus', true);
                    const ValidationAPI = feathersClient.service('/validation');
                    const checker = await ValidationAPI.find({
                        paginate: false,
                        query: { action: 'validateRegistrationEmail', email: String(v).trim() },
                    });
                    if (checker.length == 0 || checker[0].status == 0) return false;
                } catch (err) {
                    console.log(err);
                    return false;
                } finally {
                    store.commit('company/SetRegistrationEmailStatus', false);
                }
                return true;
            },
            $params: {
                type: 'companyEmailAvability',
            },
        }),
    companySubdomainAvability: (store: any) =>
        withI18nMessage({
            $validator: async (v: string) => {
                if (v === '' || String(v).length == 1) return true;
                try {
                    store.commit('company/SetRegistrationSubdomainStatus', true);
                    const ValidationAPI = feathersClient.service('/validation');
                    const checker = await ValidationAPI.find({
                        paginate: false,
                        query: { action: 'validateRegistrationSubdomain', subdomain: String(v).trim() },
                    });
                    if (checker.length == 0 || checker[0].status == 0) return false;
                } catch (err) {
                    console.log(err);
                    return false;
                } finally {
                    store.commit('company/SetRegistrationSubdomainStatus', false);
                }
                return true;
            },

            $params: {
                type: 'companySubdomainAvability',
            },
        }),
    companyPhoneAvability: (store: any) =>
        withI18nMessage({
            $validator: async (v: string) => {
                if (v === '' || String(v).length == 1) return Promise.resolve(true);
                try {
                    store.commit('company/SetRegistrationPhoneStatus', true);
                    const ValidationAPI = feathersClient.service('/validation');
                    const checker = await ValidationAPI.find({
                        paginate: false,
                        query: { action: 'validateRegistrationPhone', phone: String(v).trim() },
                    });
                    if (checker.length == 0 || checker[0].status == 0) return false;
                } catch (err) {
                    console.log(err);
                    return false;
                } finally {
                    store.commit('company/SetRegistrationPhoneStatus', false);
                }
                return true;
            },
            $params: {
                type: 'companyPhoneAvability',
            },
        }),
    companyVatAvability: (store: any) =>
        withI18nMessage({
            $validator: async (v: string) => {
                if (v === '' || String(v).length == 1) return Promise.resolve(true);
                try {
                    store.commit('company/SetRegistrationVatStatus', true);
                    const ValidationAPI = feathersClient.service('/validation');
                    const checker = await ValidationAPI.find({
                        paginate: false,
                        query: { action: 'validateRegistrationVat', vatId: String(v).trim() },
                    });
                    if (checker.length == 0 || checker[0].status == 0) return false;
                } catch (err) {
                    console.log(err);
                    return false;
                } finally {
                    store.commit('company/SetRegistrationVatStatus', false);
                }
                return true;
            },
            $params: {
                type: 'companyVatAvability',
            },
        }),
    duplicateAccountEmail: (store: any) =>
        withI18nMessage({
            $validator: async (v: string) => {
                if (v === '' || String(v).length == 1) return Promise.resolve(true);
                try {
                    store.commit('account/SetNewAccountEmailStatus', true);
                    const AccountAPI = feathersClient.service('/account');
                    const query = { email: String(v).trim(), ...queryFilters };
                    const checker = await AccountAPI.find({
                        query,
                    });
                    if (checker.total == 1) return false;
                } catch (err) {
                    console.log(err);
                    return false;
                } finally {
                    store.commit('account/SetNewAccountEmailStatus', false);
                }
                return true;
            },
            $params: {
                type: 'duplicateAccountEmail',
            },
        }),

    duplicateAccountIBAN: (store: any) =>
        withI18nMessage({
            $validator: async (v: string) => {
                if (v === '' || String(v).length == 1) return Promise.resolve(true);
                try {
                    store.commit('account/SetNewAccountIBANStatus', true);
                    const AccountAPI = feathersClient.service('/account');
                    const query = { iban: String(v).trim(), ...queryFilters };
                    const checker = await AccountAPI.find({
                        query,
                    });
                    if (checker.total == 1) return false;
                } catch (err) {
                    console.log(err);
                    return false;
                } finally {
                    store.commit('account/SetNewAccountIBANStatus', false);
                }
                return true;
            },
            $params: {
                type: 'duplicateAccountIBAN',
            },
        }),

    duplicateAccountUsername: (store: any) =>
        withI18nMessage({
            $validator: async (v: string) => {
                if (v === '' || String(v).length == 1) return Promise.resolve(true);
                try {
                    store.commit('account/SetAccountCredentialsUsernameStatus', true);
                    const AccountAPI = feathersClient.service('/account');
                    const query = { username: String(v).trim(), ...queryFilters };
                    const checker = await AccountAPI.find({
                        query,
                    });
                    if (checker.total == 1) return false;
                } catch (err) {
                    console.log(err);
                    return false;
                } finally {
                    store.commit('account/SetAccountCredentialsUsernameStatus', false);
                }
                return true;
            },
            $params: {
                type: 'duplicateAccountIBAN',
            },
        }),
    formCNPDuplicate: (store: any, formId = null) =>
        withI18nMessage({
            $validator: async (v: string) => {
                if (v === '' || String(v).length == 1) return Promise.resolve(true);
                try {
                    store.commit('form/SetCNPDuplicateStatus', true);
                    store.commit('form/SetOneYearPeriodCNPStatus', false);
                    const ValidationAPI = feathersClient.service('/validation');
                    const checker = await ValidationAPI.find({
                        paginate: false,
                        query: { action: 'validateFormCNP', formId, cnp: String(v).trim() },
                    });
                    if (checker.length == 0) return true;
                    const form = checker[0][0];
                    if (form == null) return true;
                    const currentYear = store.getters['year/currentYear'];
                    const yearDifference = +currentYear - +form.yearName;
                    if (yearDifference >= 2 || (yearDifference == 1 && form.period == 1)) {
                        return true;
                    }
                    if (yearDifference == 1 && form.period == 2) {
                        store.commit('form/SetOneYearPeriodCNPStatus', true);
                        return true;
                    }

                    return false;
                } catch (err) {
                    console.log(err);
                    return false;
                } finally {
                    store.commit('form/SetCNPDuplicateStatus', false);
                }
                return true;
            },
            $params: {
                type: 'formCNPDuplicate',
            },
        }),

    formPhoneDuplicate: (store: any, formId = null) =>
        withI18nMessage({
            $validator: async (v: string) => {
                if (v === '' || String(v).length == 1) return Promise.resolve(true);
                try {
                    store.commit('form/SetPhoneDuplicateStatus', true);
                    store.commit('form/SetOneYearPeriodPhoneStatus', false);
                    const ValidationAPI = feathersClient.service('/validation');
                    const checker = await ValidationAPI.find({
                        paginate: false,
                        query: { action: 'validateFormPhone', formId, phone: String(v).trim() },
                    });
                    if (checker.length == 0) return true;
                    const form = checker[0][0];
                    if (form == null) return true;
                    const currentYear = store.getters['year/currentYear'];
                    const yearDifference = +currentYear - +form.yearName;
                    if (yearDifference >= 2 || (yearDifference == 1 && form.period == 1)) {
                        return true;
                    }
                    if (yearDifference == 1 && form.period == 2) {
                        store.commit('form/SetOneYearPeriodPhoneStatus', true);
                        return true;
                    }

                    return false;
                } catch (err) {
                    console.log(err);
                    return false;
                } finally {
                    store.commit('form/SetPhoneDuplicateStatus', false);
                }
                return true;
            },
            $params: {
                type: 'formPhoneDuplicate',
            },
        }),
});
