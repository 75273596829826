import { computed } from 'vue';
import { useStore } from 'vuex';

export function useProductCompatibilityStatus(pid: number) {
    const store = useStore();

    const productCompatible = computed(() => {
        const productsAvailable = store.getters['ksconnect/productsAvailable'];
        const product = productsAvailable.find((p: any) => pid == p.pid);
        return product?.isCompatibleWithDevice ?? false;
    });

    return { productCompatible };
}
