import { KSConnect, KSConnectActivationWizardProgressStep, ProductAvailable, ProductReadyForActivation } from '.';
import { useLogger } from '@/composables/use-logger';
import { compare, compareVersions } from 'compare-versions';

const logger = useLogger();

export default {
    deviceInfo: (state: KSConnect) => {
        return state.info;
    },
    serviceUrlIsSet: (state: KSConnect) => {
        return state.serviceUrl != null;
    },
    deviceCompatibleWithKSConnect: (state: KSConnect) => {
        return state.deviceCompatible;
    },
    currentAppVersion: (state: KSConnect) => {
        return state.appVersion;
    },
    serviceError: (state: KSConnect) => {
        return state.error;
    },
    setupCompleted: (state: KSConnect) => {
        return state.setupComplete;
    },
    serviceEnabled: (state: KSConnect) => {
        return state.enabled;
    },
    productsCompatible: (state: KSConnect) => {
        return state.productsCompatible;
    },
    productsAvailable: (state: KSConnect) => {
        return state.productsAvailable;
    },
    installedProducts: (state: KSConnect) => {
        return state.installedProducts;
    },
    installedKeys: (state: KSConnect) => {
        return state.installedKeys;
    },
    hasRating: (state: KSConnect) => {
        return state.hasRating;
    },
    appIsLatestVersion: (state: KSConnect) => {
        if (state.appVersion == null || state.installedAppVersion == null) {
            return true;
        }

        return compare(state.installedAppVersion, state.appVersion, '>=');
    },
    activationWizardLaunched: (state: KSConnect) => state.activationWizard.launched,
    productsReadyForActivation: (__: KSConnect, getters: any, _: any, rootGetters: any) => {
        const orderProducts = rootGetters['orders/products'];
        if (orderProducts == null || orderProducts.length == 0) {
            // logger.warn('No products found in the order');
            return [];
        }
        const currentProducts = getters.productsAvailable;
        return currentProducts
            .filter((product: ProductAvailable) => product.canBeActivated && product.isCompatibleWithDevice)
            .filter((product: any) => orderProducts.some((op: any) => op.pid == product.pid))
            .map((product: ProductAvailable) => {
                const orderProduct = orderProducts.find((op: any) => op.pid == product.pid);
                return {
                    ...product,
                    image: orderProduct.data.image,
                    activation_method: orderProduct.data.activation_method,
                    opid: orderProduct.id,
                };
            }) as ProductReadyForActivation[];
    },
    activationWizardCurrentStep: (state: KSConnect) => state.activationWizard.step,
    activationWizardSelectedPid: (state: KSConnect) => state.activationWizard.selectedPid,
    activationWizardSelectedKey: (state: KSConnect) => state.activationWizard.selectedKey,
    activationWizardActivationRunning: (state: KSConnect) => state.activationWizard.activationRunning,
    activationWizardUninstallActiveKeys: (state: KSConnect) => state.activationWizard.uninstallActiveKeys,
    activationWizardActivationProgress: (state: KSConnect) => state.activationWizard.activationProgress,
    activationWizardCurrentActivationProgressStep: (state: KSConnect) => {
        const progress: KSConnectActivationWizardProgressStep[] = state.activationWizard.activationProgress;
        return progress.find((step) => step.started && !step.completed && (step?.skipped == null || !step.skipped)) ?? null;
    },
    activationWizardActivationProgressCompletedSteps: (state: KSConnect) => {
        return state.activationWizard.activationProgress.filter((step) => step.completed || step?.skipped);
    },
    activationWizardActivationComplete: (state: KSConnect) => {
        return state.activationWizard.activationProgress.every((step) => step.completed || step?.skipped);
    },
    activationWizardActivationFailed: (state: KSConnect) => {
        return state.activationWizard.activationProgress.some((step) => step.error);
    },
    activationWizardReloadOnClose: (state: KSConnect) => state.activationWizard.reloadOnClose,
    windowsUpgradeError: (state: KSConnect) => state.windowsUpgrade.upgradeError,
    windowsUpgradeRunning: (state: KSConnect) => state.windowsUpgrade.upgradeRunning,
    windowsUpgradeWindowActive: (state: KSConnect) => state.windowsUpgrade.windowActive,
    isInstallerRunning: (state: KSConnect) => state.installer.running,
    installerSetupPath: (state: KSConnect) => state.installer.setupPath,
    installerUninstallPreviousVersions: (state: KSConnect) => state.installer.uninstallPreviousVersions,
    installerSelectedLanguage: (state: KSConnect) => state.installer.lang,
    installerProgress: (state: KSConnect) => state.installer.progress,
    installerError: (state: KSConnect) => state.installer.error,
    installerStep: (state: KSConnect) => state.installer.step,
    installerTimeProgress: (state: KSConnect) => state.installer.timeProgress,
};
