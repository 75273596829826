<template>
    <div class="input-container mb-3 sm:w-9 w-11">
        <div
            class="title pt-4 sm:pt-0 px-3 text-xl md:text-3xl uppercase text-gray-100 font-semibold"
            v-if="!isAuthenticated"
        >
            Introdu codul din {{ INPUT_LENGTH }} caractere primit prin <span class="text-primary">sms</span>
        </div>
        <div v-else class="title pt-4 sm:pt-0 px-3 text-xl md:text-3xl uppercase text-gray-100 font-semibold">
            Gestionare comanda
        </div>
        <div class="form-container md:flex flex-row justify-content-center text-center mt-4">
            <div
                class="letter-container-desktop w-full flex flex-column md:flex-row justify-content-center align-items-center text-center py-6 px-1"
            >
                <PinInput
                    :input-disabled="isAuthenticated"
                    v-model="kInput"
                    :value="kInput"
                    placeholder=""
                    :length="5"
                    class="flex column-gap-2"
                    @sbmt="submitPIN"
                ></PinInput>
                <span class="ml-3 mt-5 md:mt-0" v-if="!isAuthenticated">
                    <Button
                        @click="submitPIN"
                        label="Vezi comanda"
                        class="shadow-1 text-gray-800 uppercase order-submit-btn"
                        :class="{ 'is-mobile': isMobile }"
                        :loading="isLoading"
                        :disabled="isAuthenticated"
                    ></Button>
                </span>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed, watch, defineAsyncComponent, toRefs, onMounted, inject } from 'vue';
// import { useActions } from '@u3u/vue-hooks';
import { useToast } from 'vue-toastification';
import { handleRequestError } from '@/utils/error-handlers';
import { useAuth } from '@/composables/use-auth';
import { useStore } from 'vuex';
import { useScreen, useGrid } from 'vue-screen';
// import { useReCaptcha, IReCaptchaComposition } from 'vue-recaptcha-v3';

const PinInput = import('../PinInput/Index.vue');

const INPUT_LENGTH = 5;

export default defineComponent({
    components: {
        PinInput: defineAsyncComponent(() => Promise.resolve(PinInput)),
    },
    name: 'Input',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        uid: {
            type: String,
        },
    },
    setup(props, { emit }) {
        // const { executeRecaptcha, recaptchaLoaded } = useReCaptcha() as IReCaptchaComposition;

        const isLoading = ref(false);
        const kInput = ref('');
        const toast = useToast();
        const store = useStore();
        const auth = useAuth(store);
        const { uid } = toRefs(props);

        const reportSentry: any = inject('reportSentry');
        const screen = useScreen();

        const validateInput = () => {
            kInput.value = kInput.value.replace(/\D/g, '');

            if (kInput.value == null || kInput.value.length < INPUT_LENGTH) {
                return toast.error('Codul introdus este prea scurt'), false;
            }
            return true;
        };

        const submitPIN = async () => {
            if (!validateInput()) {
                return (kInput.value = '');
            }
            isLoading.value = true;
            try {
                await auth.login({ uid: uid.value, code: kInput.value });
                isLoading.value = false;
            } catch (err: any) {
                setTimeout(() => {
                    handleRequestError(err);
                    reportSentry(err, {
                        extra: { fn: 'submidPIN', params: { uid: uid.value, code: kInput.value } },
                    });
                    isLoading.value = false;
                }, 1000);
            }
        };

        const isAuthenticated = computed(() => store.getters['auth/isAuthenticated']);
        const isMobile = computed(() => screen.width < 474);

        onMounted(() => {
            if (auth.hasPINSaved()) {
                kInput.value = auth.getSavedPIN() as string;
            }
        });

        return {
            kInput,
            INPUT_LENGTH,
            isLoading,
            isAuthenticated,
            submitPIN,
            isMobile,
        };
    },
});
</script>

<style lang="scss" scoped>
.letter-container-desktop {
    background-color: hsl(0deg 0% 100% / 9%);
    border-radius: 3px;
    backdrop-filter: blur(1px);
}

.order-submit-btn {
    padding: 1rem 2.5rem;
    border-radius: 3px;

    &.is-mobile {
        padding: 0.7rem 2rem;
    }
}

.code-input-response {
    box-shadow: none;
    border-radius: 3px;
}
</style>
