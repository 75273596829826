import { useLogger } from '@/composables/use-logger';
import axios from 'axios';

export const toValueMap = (values: any): Record<string, unknown> => {
    const reducer = (map: string, key: string) => {
        return Object.assign(map, { [key]: values[key].value });
    };
    return Object.keys(values).reduce(reducer, {} as any) as any;
};

export const createBlob = (type: string, data: string) => {
    const contentType = type;
    const sliceSize = 512;
    const b64Data = data;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {
        type: contentType,
    });
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
};

export function _base64ToArrayBuffer(base64: string) {
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
    }
    return array;
}

export function getContentTypeFromDataUrl(dataUrl: string) {
    return dataUrl.substring(dataUrl.indexOf(':') + 1, dataUrl.indexOf(';'));
}

export function chunkSubstr(str: string, size: number) {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
        chunks[i] = str.substr(o, size);
    }

    return chunks;
}

export function splitStringIntoChunks(input: string): string[] {
    const numberOfChunks = 9;
    const chunkSize = Math.ceil(input.length / numberOfChunks); // Calculate chunk size dynamically
    const result: string[] = [];

    for (let i = 0; i < numberOfChunks; i++) {
        const chunk = input.slice(i * chunkSize, (i + 1) * chunkSize);
        result.push(chunk);
    }

    return result;
}

export const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};
