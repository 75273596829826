<template>
    <div class="activation-success-container flex flex-column justify-content-center align-items-center mt-5 gap-4">
        <!-- Success Icon -->
        <i class="pi pi-check-circle text-green-500 text-6xl mb-3"></i>

        <!-- Success Message -->
        <div class="text-center text-lg font-semibold text-gray-800">Cheia de produs a fost activată cu succes!</div>

        <!-- Device Association Message -->
        <div class="text-center text-md text-gray-700">
            În urma activării automate, cheia a fost asociată acestui dispozitiv cu numele
            <span class="font-bold text-gray-900">{{ deviceName }}</span
            >.
        </div>

        <!-- Association Options -->
        <div class="flex flex-column justify-content-center align-items-center mt-2 gap-2">
            <span class="text-gray-600">Nu vrei să asociezi cheia?</span>
            <div class="flex flex-row justify-content-center align-items-center gap-2">
                <Button
                    @click="removeDeviceAssociation"
                    label="Șterge asocierea"
                    class="p-button-outlined p-button-secondary"
                    icon="pi pi-trash"
                />
                <span class="mx-1 text-gray-500">sau</span>
                <Button
                    @click="openDeviceAssocDialog"
                    label="Modifică asocierea"
                    class="p-button-outlined p-button-secondary"
                    icon="pi pi-pencil"
                />
            </div>
        </div>

        <!-- Rate Activation Procedure Section -->
        <template v-if="!hasRating">
            <div class="rate-activation-section mt-6 p-4 rounded-lg shadow-md w-full max-w-md bg-white" v-if="!isRatingSubmitted">
                <h3 class="text-center text-lg font-semibold text-gray-800 mb-3">Cum a fost procedura de activare?</h3>

                <!-- Rating Options -->
                <div class="flex justify-content-center gap-3 mb-4">
                    <Button
                        label="😃 Foarte ușor"
                        :class="['p-button-sm', rating === 1 ? 'p-button-success' : 'custom-button']"
                        @click="setRating(1)"
                    />
                    <Button
                        label="🙂 Ușor"
                        :class="['p-button-sm', rating === 2 ? 'p-button-success' : 'custom-button']"
                        @click="setRating(2)"
                    />
                    <Button
                        label="😕 Dificil"
                        :class="['p-button-sm', rating === 3 ? 'p-button-warning' : 'custom-button']"
                        @click="setRating(3)"
                    />
                    <Button
                        label="😩 Foarte dificil"
                        :class="['p-button-sm', rating === 4 ? 'p-button-danger' : 'custom-button']"
                        @click="setRating(4)"
                    />
                </div>

                <!-- Additional Feedback -->
                <div class="w-full">
                    <textarea
                        v-model="feedback"
                        rows="3"
                        :placeholder="rating != null && rating > 1 ? 'Cum ne putem imbunatati?' : 'Spune-ne mai multe'"
                        class="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                    ></textarea>
                </div>

                <!-- Submit and Cancel Buttons -->
                <div class="flex justify-content-center gap-3 mt-4">
                    <Button
                        :disabled="!rating"
                        :loading="isRatingSubmitting"
                        label="Trimite"
                        @click="submitFeedback"
                        class="p-button-primary"
                    />
                    <Button
                        v-if="rating"
                        :disabled="isRatingSubmitting"
                        label="Anulează"
                        @click="cancelFeedback"
                        class="p-button-outlined p-button-secondary"
                    />
                </div>
            </div>
            <!-- Thank You Message -->
            <div
                v-else
                class="thank-you-message flex flex-column justify-content-center align-items-center mt-6 p-4 rounded-lg shadow-md w-full max-w-md bg-white text-center"
            >
                <i class="pi pi-thumbs-up text-green-500 text-4xl mb-1"></i>
                <h3 class="text-lg font-semibold text-gray-800 mb-0">Mulțumim pentru feedback!</h3>
                <p class="text-gray-700">
                    Ne bucurăm că ai împărtășit experiența ta. Feedback-ul tău ne ajută să îmbunătățim serviciul nostru.
                </p>
            </div>
        </template>

        <!-- Device Association Dialog -->
        <ProductDeviceAssoc :orderKeyId="selectedKeyId" v-if="isDeviceAssocDialogActive" @close="closeDeviceAssocDialog" />
    </div>
</template>

<script setup lang="ts">
import { useKeySelected } from '@/composables/ksconnect/use-key-selected';
import feathersClient from '@/feathers-client';
import emitter from '@/models/emitter.class';
import { handleRequestError } from '@/utils/error-handlers';
import { useConfirm } from 'primevue/useconfirm';
import { computed, onBeforeUnmount, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

import ProductDeviceAssoc from '@/components/OrderInfo/ProductDeviceAssoc.vue';

const OrderKeysDevicesAPI = feathersClient.service('/orders/keys/devices');
const KSConnectRatingsAPI = feathersClient.service('/ksconnect_ratings');

const store = useStore();

const confirm = useConfirm();
const toast = useToast();

const deviceName = computed(() => store.getters['ksconnect/deviceInfo']?.hostname);
const hasRating = computed(() => store.getters['ksconnect/hasRating']);
const selectedKeyId = computed(() => store.getters['ksconnect/activationWizardSelectedKey']);
const { selectedKey } = useKeySelected(selectedKeyId.value);
const isKeyDeviceRemoving = ref(false);
const isDeviceAssocDialogActive = ref(false);
const isRatingSubmitted = ref(false);
const isRatingSubmitting = ref(false);

const rating = ref<number | null>(null);
const feedback = ref('');

const openDeviceAssocDialog = () => {
    isDeviceAssocDialogActive.value = true;
};

const closeDeviceAssocDialog = (refresh = false) => {
    isDeviceAssocDialogActive.value = false;
    emitter.emit('activation-wizard-closed');
    store.commit('ksconnect/CloseActivationWizard');
};

const setRating = (value: number | null) => {
    rating.value = value;
};

const submitFeedback = async () => {
    try {
        isRatingSubmitting.value = true;
        await KSConnectRatingsAPI.create({
            rating: rating.value,
            extra: feedback.value,
        });
        isRatingSubmitted.value = true;
    } catch (err: any) {
        handleRequestError(err);
    } finally {
        isRatingSubmitting.value = false;
    }
};

const cancelFeedback = () => {
    rating.value = null;
    feedback.value = '';
};

const removeDeviceAssociation = async () => {
    const confirmation = await new Promise((resolve) => {
        confirm.require({
            group: 'headless',
            message: 'Sigur dorești să ștergi asocierea cu dispozitivul?',
            header: 'Ștergere asociere',
            accept: () => {
                resolve(true);
                confirm.close();
            },
            reject: () => {
                resolve(false);
                confirm.close();
            },
        });
    }).catch(() => false);
    if (!confirmation) {
        return false;
    }

    try {
        isKeyDeviceRemoving.value = true;
        const association = (await OrderKeysDevicesAPI.find({
            query: {
                orderKeyId: selectedKeyId.value,
            },
        })) as any[];
        if (association.length === 0) {
            toast('Cheia nu este asociată niciunui dispozitiv');
            return;
        }

        await OrderKeysDevicesAPI.remove(association[0].id);
        toast('Asocierea a fost ștearsă');
        // close activation wizard
        emitter.emit('activation-wizard-closed');
        store.commit('ksconnect/CloseActivationWizard');
    } catch (err: any) {
        handleRequestError(err);
    } finally {
        isKeyDeviceRemoving.value = false;
    }
};

onBeforeUnmount(() => {
    if (isRatingSubmitted.value) {
        store.commit('ksconnect/SetHasRating', true);
    }
});
</script>

<style scoped>
.activation-success-container {
    padding: 1.5rem;
    border-radius: 8px;
}

.rate-activation-section {
    border-radius: 8px;
    background-color: #ffffff;
}

/* Custom default button color for unselected rating */
.custom-button {
    border-color: #cccccc;
    color: var(--secondary-color);
    background-color: var(--yellow-50);
}
</style>
