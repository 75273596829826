<template>
    <Dialog
        v-model:visible="visible"
        modal
        :closeOnEscape="false"
        :closable="false"
        :class="{
            'activation-dialog': true,
            'col-12': true,
            'md:col-8': true,
            'lg:col-6': currentStep == 4,
        }"
    >
        <div class="pt-4">
            <template v-if="activationComplete">
                <AWActivationComplete></AWActivationComplete>
            </template>
            <template v-else>
                <component :is="currentStepComputed"></component>
            </template>
        </div>
        <div>
            <!-- <Message :closable="false" class="text-center my-4" severity="error" v-if="activationFailed"> -->
            <!--     Activarea a esuat 😥. Te rugam sa incerci din nou sau sa contactezi suportul tehnic prin email sau -->
            <!--     telefon. -->
            <!-- </Message> -->
        </div>
        <template #footer>
            <div class="pt-4 flex flex-row gap-3">
                <Button
                    v-if="currentStep < 4 || activationComplete || activationFailed"
                    text
                    label="Inchide"
                    severity="secondary"
                    @click="closeActivationWizard"
                ></Button>
                <Button
                    v-if="currentStep < 4 && !windowsUpgradeWindowActive"
                    :disabled="isConfirmButtonDisabled"
                    type="button"
                    :label="currentStep < 3 ? 'Urmatorul' : 'Activeaza'"
                    @click="goNextStep"
                    severity="secondary"
                ></Button>
            </div>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useConfirm } from 'primevue/useconfirm';
import AWProductSelect from './AWProductSelect.vue';
import AWKeySelect from './AWKeySelect.vue';
import AWSelectionConfirm from './AWSelectionConfirm.vue';
import AWActivate from './AWActivate.vue';
import AWActivationComplete from './AWActivationComplete.vue';
import emitter from '@/models/emitter.class';

const store = useStore();
const confirm = useConfirm();

const stepsComponents = {
    1: AWProductSelect,
    2: AWKeySelect,
    3: AWSelectionConfirm,
    4: AWActivate,
};

const closeActivationWizard = () => {
    const reloadOnClose = store.getters['ksconnect/activationWizardReloadOnClose'];
    if (reloadOnClose || currentStep.value >= 4) {
        emitter.emit('activation-wizard-closed');
    }
    store.commit('ksconnect/CloseActivationWizard');
};

const windowsUpgradeWindowActive = computed(() => store.getters['ksconnect/windowsUpgradeWindowActive']);
const selectedPid = computed(() => store.getters['ksconnect/activationWizardSelectedPid']);
const ordersProducts = computed(() => store.getters['orders/products']);
const selectedKey = computed<Record<string, any>>(() => {
    const orderProduct = ordersProducts.value.find((product: Record<string, any>) => product?.pid == selectedPid.value);

    if (!orderProduct) {
        return [];
    }
    const key = orderProduct?.keys?.find(
        (key: Record<string, any>) => key.id == store.getters['ksconnect/activationWizardSelectedKey']
    );

    return {
        id: key.id,
        changeRequest: key.orders_keys_change_request != null,
        device: key.orders_keys_device?.device,
        cid: key.orders_keys_cid?.cid,
        key: key.used_key?.cdkey?.cdkey,
    };
});

const goNextStep = async () => {
    if (store.getters['ksconnect/activationWizardCurrentStep'] == 3 && selectedKey.value?.device != null) {
        const confirmation = await new Promise((resolve, reject) => {
            confirm.require({
                group: 'headless',
                message: 'Cheia este asociata unui alt dispozitiv. Sigur continui activarea?',
                header: 'Confirmare activare',
                accept: () => {
                    resolve(true);
                    confirm.close();
                },
                reject: () => {
                    resolve(false);
                    confirm.close();
                },
            });
        }).catch(() => false);
        if (!confirmation) {
            return false;
        }
    }
    store.commit('ksconnect/SetActivationWizardNextStep');
};

const currentStep = computed(() => {
    return store.getters['ksconnect/activationWizardCurrentStep'];
});

const currentStepComputed = computed(() => {
    switch (currentStep.value) {
        case 1:
            return stepsComponents[1];

        case 2:
            return stepsComponents[2];

        case 3:
            return stepsComponents[3];

        case 4:
            return stepsComponents[4];
    }
});

const activationRunning = computed(() => {
    return store.getters['ksconnect/activationWizardActivationRunning'];
});

const activationComplete = computed(() => {
    return store.getters['ksconnect/activationWizardActivationComplete'];
});

const activationFailed = computed(() => {
    return store.getters['ksconnect/activationWizardActivationFailed'];
});

const isConfirmButtonDisabled = computed(() => {
    if (store.getters['ksconnect/activationWizardSelectedPid'] == null) {
        return true;
    }

    if (
        store.getters['ksconnect/activationWizardCurrentStep'] == 2 &&
        store.getters['ksconnect/activationWizardSelectedKey'] == null
    ) {
        return true;
    }

    return false;
});

const visible = true;
</script>

<style scoped></style>
