<template>
    <div class="navbar bg-black py-3 px-5 flex align-items-center justify-content-between relative lg:static">
        <img src="/images/logo_keys_full_white.svg" alt="Image" height="40" class="mr-0 lg:mr-6" />
        <a
            v-ripple
            v-if="isAuthenticated"
            class="cursor-pointer block lg:hidden text-gray-100 mt-1 p-ripple"
            v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }"
        >
            <i class="pi pi-bars text-4xl"></i>
        </a>
        <div
            class="align-items-center meniu-items flex-grow-1 justify-content-end hidden lg:flex absolute lg:static w-full bg-black left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none"
        >
            <ul
                class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row gap-3 py-3 lg:py-0 text-left"
            >
                <li v-if="!isCorder">
                    <a href="https://keysoutlet.ro" target="_blank">
                        <Button
                            label="Magazin"
                            icon="pi pi-shopping-bag"
                            iconPos="left"
                            class="text-gray-200 p-button-text px-5 lg:px-3 lg:py-2 align-items-center text-gray-100 hover:text-white hover:bg-gray-900 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                        ></Button>
                    </a>
                </li>
                <li>
                    <a href="https://help.keysoutlet.ro?utm_source=pgc" target="_blank">
                        <Button
                            label="Help center"
                            icon="pi pi-book"
                            iconPos="left"
                            class="text-gray-200 p-button-text px-5 lg:px-3 lg:py-2 align-items-center text-gray-100 hover:text-white hover:bg-gray-900 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                        ></Button>
                    </a>
                </li>
                <li>
                    <a href="https://status.keysoutlet.ro" target="_blank">
                        <Button
                            label="Stare servicii"
                            icon="pi pi-server"
                            iconPos="left"
                            class="text-gray-200 p-button-text px-5 lg:px-3 lg:py-2 align-items-center text-gray-100 hover:text-white hover:bg-gray-900 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                        ></Button>
                    </a>
                </li>

                <li v-if="isAuthenticated">
                    <Button
                        label="Inchide sesiunea"
                        icon="pi pi-sign-out"
                        iconPos="left"
                        @click="doLogout"
                        class="text-gray-200 p-button-text px-5 lg:px-3 lg:py-2 align-items-center text-gray-100 hover:text-white hover:bg-gray-900 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                    ></Button>
                </li>
                <!-- <li>
                    <Button
                        label="CONTACT"
                        class="text-gray-800 contact-button  ml-5 lg:ml-3 lg:my-2 align-items-center text-gray-100 hover:text-white font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                    ></Button>
                </li> -->
            </ul>
        </div>
    </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue';
import { useAuth } from '@/composables/use-auth';
import { useStore } from 'vuex';
export default defineComponent({
    setup(props, ctx) {
        const store = useStore();
        const auth = useAuth(store);

        const doLogout = () => auth.logout();

        const isAuthenticated = computed(() => store.getters['auth/isAuthenticated']);
        const isCorder = computed(() => store.getters['orders/isCorder']);
        return { doLogout, isAuthenticated, isCorder };
    },
});
</script>
<style lang="scss" scoped>
.meniu-items {
    border-width: 1px 0px 1px 0px !important;
}
</style>
