import { Installer, KSConnect, KSConnectActivationWizard, KSConnectWindowsUpgrade, ProductTypes } from '.';

export const installerState = (): Installer => ({
    running: false,
    step: null,
    uninstallPreviousVersions: '0',
    lang: null,
    error: null,
    progress: null,
    setupPath: null,
    timeProgress: null,
});

export const windowsUpgradeState = (): KSConnectWindowsUpgrade => ({
    upgradeRunning: false,
    upgradeError: null,
    windowActive: false,
});

export const activationWizardState = (): KSConnectActivationWizard => ({
    launched: false,
    reloadOnClose: false,
    step: 1,
    selectedPid: null,
    selectedKey: null,
    activationRunning: false,
    uninstallActiveKeys: '1',
    activationProgress: [
        {
            step: 1,
            started: false,
            completed: false,
            error: false,
            name: 'keyCheck',
            description: 'Se verifica cheia de activare',
        },
        {
            step: 2,
            started: false,
            completed: false,
            error: false,
            name: 'cleanInstalledKeys',
            description: 'Se sterg cheile instalate dar inactive',
        },
        {
            step: 3,
            started: false,
            completed: false,
            error: false,
            name: 'installProductKey',
            description: 'Se instaleaza cheia de activare',
        },
        {
            step: 4,
            started: false,
            completed: false,
            error: false,
            name: 'onlineActivation',
            description: 'Se incearca activarea online',
        },
        {
            step: 5,
            started: false,
            completed: false,
            error: false,
            name: 'alternativeActivation',
            description: 'Se incearca activarea alternativa. Nu inchide aceasta fereastra!',
        },
        {
            step: 6,
            started: false,
            completed: false,
            error: false,
            name: 'cleanup',
            description: 'Se finalizeaza activarea',
        },
    ],
});

export const initialKSConnectStore = (): KSConnect => {
    return {
        appVersion: null,
        installedAppVersion: null,
        deviceCompatible: false,
        productsCompatible: false,
        hasRating: false,
        enabled: false,
        error: null,
        setupComplete: false,
        serviceUrl: null,
        possiblePorts: [50000, 50001, 50002, 50003, 50004],
        serviceApi: null,
        info: null,
        activationWizard: activationWizardState(),
        productsAvailable: [],
        windowsUpgrade: windowsUpgradeState(),
        installer: installerState(),
        installedKeys: [],
        installedProducts: {
            [ProductTypes.OFFICE]: {
                list: [],
                filterRegex: /Office.*(Prof.*|Home.*|LTS.*)\s20\d{2}/,
                productsFetched: false,
            },
            [ProductTypes.VISIO]: {
                list: [],
                filterRegex: /Visio.*20\d{2}.*/,
                productsFetched: false,
            },
            [ProductTypes.PROJECT]: {
                list: [],
                filterRegex: /Project.*20\d{2}.*/,
                productsFetched: false,
            },
        },
    };
};
