<template>
    <Dialog
        :closable="false"
        :closeOnEscape="false"
        visible
        header="Asociaza cheia cu dispozitiv"
        modal
        class="p-fluid p-3"
    >
        <errors-list :errors="registrationErrors"></errors-list>
        <InlineMessage severity="info" class="mb-2 justify-content-start">
            Asocierea este optionala si are scop gestionarea mai usoara a cheilor tale
        </InlineMessage>
        <InlineMessage severity="info" class="mb-4 justify-content-start">
            Aceasta asociere nu are legatura cu inregistrarea licentei pe dispozitiv sau in contul Microsoft
        </InlineMessage>

        <br />
        <div class="field">
            <span
                :class="{
                    'p-float-label': false,
                    'w-full': true,
                }"
            >
                <label
                    for="device"
                    :class="{
                        'p-error': v$.device.$invalid && v$.device.$dirty,
                        block: true,
                        'font-medium': true,
                        'mb-2': true,
                    }"
                    >Nume dispozitiv (ex. ANDREI-PC)</label
                >
                <InputText
                    id="device"
                    v-model.lazy.trim="form.device"
                    @blur="v$.device.$touch()"
                    @focus="v$.device.$reset()"
                    :class="{
                        'w-full': true,
                        'p-valid': !v$.device.$invalid && v$.device.$dirty,
                        'p-invalid': v$.device.$invalid && v$.device.$dirty,
                    }"
                    type="text"
                ></InputText>
            </span>
            <small v-if="(v$.device.$invalid && v$.device.$dirty) || v$.device.$pending.$response" class="p-error">{{
                v$.device.$errors[0].$message
            }}</small>
        </div>

        <template #footer>
            <Button label="Anuleaza" icon="pi pi-times" class="p-button-text text-bluegray-800" @click="hideDialog" />
            <RoleButton :loading="isRequestPending" label="Salveaza" icon="pi pi-check" @click="saveRequest(v$)" />
        </template>
    </Dialog>
</template>
<script lang="ts">
import { computed, defineComponent, inject, onMounted, reactive, Ref, ref, toRefs, watch } from 'vue';
import useValidators from '@composables/use-validators';
import useVuelidate from '@vuelidate/core';
// import { useReCaptcha, IReCaptchaComposition } from 'vue-recaptcha-v3';
import { useToast } from 'vue-toastification';

import feathersClient from '@/feathers-client';
import { handleRequestError } from '@/utils/error-handlers';

export default defineComponent({
    props: {
        orderKeyId: {
            type: Number,
            required: true,
        },
    },
    setup(props, ctx) {
        // const { executeRecaptcha, recaptchaLoaded } = useReCaptcha() as IReCaptchaComposition;

        const registrationErrors: Ref = ref([]);
        const isRequestPending: Ref = ref(false);
        const isGettingCompanyData: Ref = ref(false);
        const toast = useToast();
        const reportSentry: any = inject('reportSentry');
        const { orderKeyId: oKeyId } = toRefs(props);

        const validators = useValidators({});
        const OrdersKeysDevicesAPI = feathersClient.service('/orders/keys/devices');

        const form = reactive({
            device: '',
        });

        const rules: any = {
            device: {
                required: validators.required,
                minLength: validators.minLength(3),
                maxLength: validators.maxLength(30),
            },
        };
        const v$: any = useVuelidate(rules, form, { $lazy: true });

        const hideDialog = () => {
            ctx.emit('close');
        };

        const saveRequest = async (v: any) => {
            registrationErrors.value = [];

            const isFormCorrect = await v.$validate();
            if (!isFormCorrect) return;
            let token;
            try {
                isRequestPending.value = true;
                // await recaptchaLoaded();
                // token = await executeRecaptcha('lscontrol_orderkeydevice');
                await OrdersKeysDevicesAPI.create({ orderKeyId: oKeyId.value, ...form });
                // window.location.reload();
                toast('Cheia a fost asociata unui dispozitiv');
                ctx.emit('close', true);
            } catch (err: any) {
                console.log(err);
                handleRequestError(err, registrationErrors);
                reportSentry(err, {
                    extra: { fn: 'productDeviceAssocSaveReq', params: { ...form } },
                });
            } finally {
                isRequestPending.value = false;
            }
        };

        return {
            registrationErrors,
            hideDialog,
            isRequestPending,
            saveRequest,
            v$,
            form,
        };
    },
});
</script>
<style lang="scss" scoped></style>
