<template>
    <div
        class="flex flex-row justify-content-start align-items-center gap-2"
        :class="{
            'text-green-500': !keyIsReported,
            'text-red-500': keyIsReported,
        }"
    >
        <i
            :class="{
                pi: true,
                'pi-check': !keyIsReported,
                'pi-times': keyIsReported,
            }"
        ></i>
        <div v-if="keyIsReported">Cheia de produs este raportata. Asteapta raspunsul echipei de suport</div>
        <div v-else>Cheia de produs nu este raportata</div>
    </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';
import { useKeySelected } from '@/composables/ksconnect/use-key-selected';

const props = defineProps<{
    keyId: number;
}>();

const { keyId } = toRefs(props);

const { keyIsReported } = useKeySelected(keyId.value);
</script>

<style scoped></style>
