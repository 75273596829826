import { OrderProduct } from '@/store/services/orders';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useLogger } from '@/composables/use-logger';
import { KSConnectInfo, ProductAvailable, ProductTypes } from '@/store/ksconnect';
import { useConfirm } from 'primevue/useconfirm';

export function useWindowsUpgrade(pid: number) {
    const store = useStore();
    const logger = useLogger();
    const confirm = useConfirm();

    const product = computed<OrderProduct | null>(() => {
        const orderProduct = store.getters['orders/products'].find((op: OrderProduct) => op.pid == pid);
        if (orderProduct == null) {
            logger.error(`No product with pid ${pid} found in order`);
            return null;
        }
        return orderProduct;
    });

    const deviceInfo = computed<KSConnectInfo>(() => {
        return store.getters['ksconnect/deviceInfo'] ?? {};
    });

    const upgradeError = computed(() => store.getters['ksconnect/windowsUpgradeError']);
    const upgradeRunning = computed(() => store.getters['ksconnect/windowsUpgradeRunning']);

    const haveWindowsProducts = computed(() => {
        return store.getters['ksconnect/productsAvailable'].some(
            (product: ProductAvailable) => product.type === ProductTypes.WINDOWS
        );
    });

    const windowsNeedsUpgrade = computed(() => {
        if (product.value == null) {
            return false;
        }
        if (!productIsWindows.value) {
            return false;
        }
        let { os_version, os_build, os_caption } = deviceInfo.value;
        if (os_version == null || os_build == null) {
            logger.info('Device info not available');
            return false;
        }
        let {
            data: { name },
        } = product.value;
        os_caption = os_caption.toLowerCase();
        name = name.toLowerCase();

        if (!name.toLowerCase().includes('windows 10') && !name.toLowerCase().includes('windows 11')) {
            // We dont' upgrade version lower than Windows 10
            return false;
        }

        switch (true) {
            case os_caption.includes('home') && name.includes('pro'):
                return true;
            case os_caption.includes('home') && name.includes('enterprise'):
                return true;
            case os_caption.includes('pro') && name.includes('enterprise'):
                return true;
            default:
                return false;
        }
    });

    const upgradeInfo = computed(() => {
        if (product.value == null) {
            return null;
        }
        let { os_version, os_build, os_caption } = deviceInfo.value;
        if (os_version == null || os_build == null) {
            logger.info('Device info not available');
            return null;
        }
        let {
            data: { name },
        } = product.value;
        os_caption = os_caption.toLowerCase();
        name = name.toLowerCase();

        switch (true) {
            case os_caption.includes('home n') && name.includes('pro n'):
                return {
                    from: 'HOME_N',
                    to: 'PRO_N',
                };

            case os_caption.includes('home') && name.includes('pro'):
                return {
                    from: 'HOME',
                    to: 'PRO',
                };
            case os_caption.includes('home') && name.includes('enterprise'):
                return {
                    from: 'HOME',
                    to: 'ENTERPRISE',
                };
            case os_caption.includes('pro') && name.includes('enterprise'):
                return {
                    from: 'PRO',
                    to: 'ENTERPRISE',
                };
            default:
                return null;
        }
    });

    const productIsWindows = computed(() => {
        if (product.value == null) {
            return false;
        }
        let {
            data: { products_appconfig },
        } = product.value;

        if (products_appconfig == null) {
            return false;
        }
        return products_appconfig?.pType === ProductTypes.WINDOWS;
    });

    const upgradeWindows = async () => {
        const confirmation = await new Promise((resolve, reject) => {
            confirm.require({
                group: 'headless',
                message:
                    'Te rugam sa nu opresti dispozitivul in timpul procesului de upgrade. Procesul poate dura cateva minute.',
                header: 'Confirmare',
                accept: () => {
                    resolve(true);
                    confirm.close();
                },
                reject: () => {
                    resolve(false);
                    confirm.close();
                },
            });
        }).catch(() => false);
        if (!confirmation) {
            return false;
        }

        await store.dispatch('ksconnect/UpgradeWindows', {
            from: upgradeInfo.value?.from,
            to: upgradeInfo.value?.to,
        });
    };

    return {
        productIsWindows,
        windowsNeedsUpgrade,
        upgradeInfo,
        product,
        upgradeWindows,
        upgradeError,
        upgradeRunning,
    };
}
