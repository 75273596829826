import { ProductTypes, ProductInstallInfo, ProductAvailable, LicenseStatus, InstalledKey } from '@/store/ksconnect';
import { OrderProduct } from '@/store/services/orders';
import { pid } from 'process';
import { computed, toRefs } from 'vue';
import { useStore } from 'vuex';

export function useKeySelected(keyId: number) {
    const store = useStore();
    const ordersProducts = computed<OrderProduct[]>(() => store.getters['orders/products']);

    const selectedKey = computed<{
        id: number;
        changeRequest: boolean;
        device: string | null;
        cid: string | null;
        iid: string | null;
        key: string | null;
        pid: number;
    } | null>(() => {
        for (const product of ordersProducts.value) {
            const key = product?.keys?.find((key: Record<string, any>) => key.id == keyId);
            if (key != null) {
                return {
                    id: key.id,
                    changeRequest: key?.orders_keys_change_request != null,
                    device: key?.orders_keys_device?.device,
                    cid: key?.orders_keys_cid?.cid,
                    iid: key?.orders_keys_cid?.iid,
                    key: key.used_key?.cdkey?.cdkey,
                    pid: product.pid,
                };
            }
        }

        return null;
    });

    const keyHasDeviceAssociated = computed(() => selectedKey.value?.device != null);
    const keyDevice = computed(() => selectedKey.value?.device);
    const keyIsReported = computed(() => selectedKey.value?.changeRequest);
    const installedProducts = computed<Record<Exclude<ProductTypes, ProductTypes.WINDOWS>, ProductInstallInfo>>(() => {
        return store.getters['ksconnect/installedProducts'];
    });

    const keyIsActiveOnDevice = computed(() => {
        const ksProduct: ProductAvailable = store.getters['ksconnect/productsAvailable'].find(
            (product: ProductAvailable) => product.pid == selectedKey.value?.pid
        );
        if (!ksProduct) {
            return false;
        }
        const currentPartialKey = selectedKey.value?.key?.split('-').at(-1);
        return store.getters['ksconnect/installedKeys'].some(
            (key: InstalledKey) => key.partial_product_key === currentPartialKey && key.license_status == LicenseStatus.LICENSED
        );
    });

    return {
        selectedKey,
        keyHasDeviceAssociated,
        keyDevice,
        keyIsReported,
        keyIsActiveOnDevice,
    };
}
