import { onMounted, ref, defineEmits, computed, watch } from 'vue';
import { useEnv } from '../use-env';
import { useToast } from 'vue-toastification';
import arch from 'arch';
import { useStore } from 'vuex';

export function useKSConnectDownload() {
    const env = useEnv();
    const toast = useToast();
    const store = useStore();

    const tosAgreed = ref(0);
    const downloadLink = ref('');
    const downloadStarted = ref(false);

    const isKSConnectDialogActive = ref(false);

    const openKSConnectDialog = () => {
        isKSConnectDialogActive.value = true;
    };

    const closeKSConnectDialog = (refresh: boolean) => {
        if (refresh) {
            window.location.reload();
        }
        isKSConnectDialogActive.value = false;
    };

    const currentAppVersion = computed(() => store.getters['ksconnect/currentAppVersion']);

    const downloadApp = () => {
        if (!env.has('BACKEND_URL')) {
            return toast.error('Download link not available');
        }
        const a = document.createElement('a');
        a.href = downloadLink.value;
        a.id = 'ksconnect-app-download';
        a.target = '_blank';
        a.click();
        a.remove();
        downloadStarted.value = true;
    };

    const createDownlodLink = () => {
        //ksconnect_64bit_0_5_2.exe
        let appName = 'ksconnect';
        const currentArch = arch();
        if (currentArch == 'x64') {
            appName = `${appName}_64bit`;
        } else {
            appName = `${appName}_32bit`;
        }
        if (currentAppVersion.value) {
            const version = currentAppVersion.value.split('.').join('_');
            appName = `${appName}_${version}.exe`;
        } else {
            appName = `${appName}.exe`;
        }

        downloadLink.value = `${env.get('BACKEND_URL')}/ksconnect-download?arch=${currentArch}&t=${Date.now()}`;
    };

    const tosAgreedComputed = computed(() => {
        return tosAgreed.value != 0 && Array.isArray(tosAgreed.value) && tosAgreed.value.length > 0;
    });

    onMounted(createDownlodLink);

    return {
        isKSConnectDialogActive,
        openKSConnectDialog,
        closeKSConnectDialog,
        downloadLink,
        downloadApp,
        tosAgreed,
        downloadStarted,
        tosAgreedComputed,
    };
}
