<template>
    <div class="flex flex-column justify-content-center">
        <div class="px-3 w-full">
            <i class="pi pi-microsoft upgrade-icon text-center mb-5 block"></i>
            <div class="flex flex-column align-items-start justify-content-start">
                <div>
                    Am detectat ca versiunea de Windows instalata pe dispozitiv (<strong>{{ upgradeFromVersion }}</strong
                    >) nu este compatibila cu produsul (<strong>{{ product.data.name }}</strong
                    >).
                </div>
                <div>
                    Pentru a continua, este necesar upgrade-ul la o versiune compatibila (<strong>{{ upgradeToVersion }}</strong
                    >).
                </div>
            </div>
        </div>
        <div class="px-3 mt-2 line-height-2">
            <div class="font-semibold">Ce trebuie sa sti:</div>
            <ul class="mt-1">
                <li>Upgrade-ul se va efectua automat si nu vei pierde datele de pe dispozitiv.</li>
                <li>Performanta nu este afectata intrucat modificarile la nivel de sistem sunt minime si insesizabile.</li>
                <li>Procesul dureaza 3-5 minute iar <strong>computerul se va restarta automat la final</strong>.</li>
                <li>Dupa upgrade, revino la procesul de activare.</li>
                <li>
                    <strong>Nu inchide fereastra de upgrade sau dispozitivul pana la finalizarea procesului.</strong>
                </li>
            </ul>
        </div>
        <div class="text-center mt-3 mb-0">
            <Button
                @click="upgradeWindows"
                :label="upgradeRunning ? 'Upgrade in curs...' : 'Am inteles, vreau sa fac upgrade'"
                :disabled="upgradeError != null"
                :loading="upgradeRunning"
                class="upgrade-btn p-button-primary mb-0"
            />
        </div>

        <Message :closable="false" class="upgrade-error text-center mt-4" severity="error" v-if="upgradeError">{{
            upgradeError
        }}</Message>
        <Message :closable="false" class="upgrade-info text-center mt-4" severity="info" v-if="upgradeRunning">
            Daca fereastra de upgrade nu se deschide, te rugam sa reiei procesul de upgrade sau sa contactezi suportul tehnic.
        </Message>
    </div>
</template>

<script setup lang="ts">
import { computed, defineProps, onBeforeMount, onBeforeUnmount, toRefs } from 'vue';
import { useWindowsUpgrade } from '@/composables/ksconnect/use-windows-upgrade';
import { useStore } from 'vuex';

const props = defineProps<{ pid: number }>();
const store = useStore();

const { pid } = toRefs(props);

const { upgradeInfo, product, upgradeWindows, upgradeError, upgradeRunning } = useWindowsUpgrade(pid.value);

const upgradeFromVersion = computed(() => {
    return upgradeInfo.value?.from;
});

const upgradeToVersion = computed(() => {
    return upgradeInfo.value?.to;
});
onBeforeMount(() => store.commit('ksconnect/SetWindowsUpgradeWindowActive', true));
onBeforeUnmount(() => store.commit('ksconnect/SetWindowsUpgradeWindowActive', false));
</script>

<style scoped>
.upgrade-icon {
    font-size: 6rem;
}

.upgrade-btn {
    padding: 0.8rem 2rem;
    font-size: 1.02rem;
}
</style>
