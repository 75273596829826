import { useEnv } from '@/composables/use-env';
import feathersClient from '@/feathers-client';
import { findInstalledApp, getInstallationStatus } from '@/utils/ksconnect-helpers';
import axios from 'axios';
import DeviceDetector from 'device-detector-js';
import {
    InstallerCheckProgress,
    InstallerSteps,
    KSConnect,
    ProductAvailable,
    ProductInstalledStatuses,
    ProductReadyForActivation,
    ProductTypes,
} from '.';
import { ExecutionTimer } from '@/utils/execution-timer';

import { useLogger } from '@/composables/use-logger';
import { OrderProduct, OrderProductKey } from '../services/orders';
import getters from './getters';
import { sleep } from '@/utils/common';

const logger = useLogger();

export default {
    FindService: async ({ state, commit }: { state: any; commit: any }) => {
        const env = useEnv();
        const host = env.get('KSCONNECT_HOST');
        const cancelTokenSource = axios.CancelToken.source();
        let found = false;

        // Create an array of promises for each port check with reduced timeout
        const promises = state.possiblePorts.map((port: number) => {
            if (found) return null;

            const url = `http://${host}:${port}`;
            return axios
                .head(`${url}/health`, {
                    timeout: 1000, // Reduced timeout to 1000ms
                    cancelToken: cancelTokenSource.token,
                })
                .then((response) => {
                    if (response.status === 200 && !found) {
                        found = true;
                        cancelTokenSource.cancel('Service found');
                        commit('SetUrl', url);
                        logger.info('KSConnect service found at:', url);
                        return url;
                    }
                })
                .catch((error) => {
                    if (!axios.isCancel(error)) {
                        logger.info('Error checking:', url, 'Error:', error);
                    }
                    return null;
                });
        });

        // Use Promise.any() for the fastest resolved response or null
        await Promise.any(promises.filter(Boolean));
    },
    Setup: async ({ commit, getters, dispatch }: { commit: any; dispatch: any; state: KSConnect; getters: any }) => {
        const env = useEnv();
        const host = env.get('KSCONNECT_HOST');
        if (!host) {
            logger.info('KSConnect host not set');
            return;
        }

        // Check if we are on desktop and it's windows
        const deviceDetector = new DeviceDetector();
        const device = deviceDetector.parse(window.navigator.userAgent);
        // logger.info(device);
        if (device?.bot != null) {
            logger.info('KSConnect is disabled on bots');
            return;
        }
        if (device?.device?.type != 'desktop') {
            logger.info('KSConnect is disabled on mobile devices');
            return;
        }
        if (device?.os?.name != 'Windows') {
            logger.info('KSConnect is disabled on non-windows devices');
            return;
        }

        commit('SetDeviceCompatible', true);

        await dispatch('FindService');

        if (!getters.serviceUrlIsSet) {
            logger.info('KSConnect service not found');
            return;
        }
        commit('SetEnabled', true);
        commit('SetServiceApi');

        if (
            !(await dispatch('FetchInfo').catch(() => {
                logger.error('KSConnect service not available:');
                return false;
            }))
        ) {
            commit('SetServiceError', 'Eroare la obtinerea informatiilor de pe dispozitiv');
            commit('SetEnabled', false);
            return;
        }

        if (
            !(await dispatch('GetServiceAuthToken').catch((err: any) => {
                logger.error('KSConnect service not available:', err);
                return false;
            }))
        ) {
            commit('SetServiceError', 'Eroare la obtinerea cheii de autentificare');
            commit('SetEnabled', false);
            return;
        }

        if (getters.serviceEnabled) {
            commit('SetSetupCompleted', true);
            logger.info('KSConnect is enabled');
        }
    },
    FetchInfo: async ({ commit, state }: { commit: any; state: KSConnect }) => {
        if (state.serviceApi == null) {
            logger.info('Service API not set');
            commit('SetEnabled', false);
            return;
        }
        try {
            const info = await state.serviceApi.fetchInfo();
            commit('SetInfo', info);
            return true;
        } catch (err: any) {
            logger.info('KSConnect service not available:', err);
            commit('SetEnabled', false);
            return false;
        }
    },
    GetServiceAuthToken: async ({ state, commit }: { state: KSConnect; commit: any }) => {
        if (state.serviceApi == null) {
            logger.info('Service API not set');
            commit('SetEnabled', false);
            return false;
        }
        try {
            logger.info('state.info:', state);
            if (state.info == null) {
                throw new Error('KSConnect info not set');
            }
            const response = await feathersClient.service('ksconnect').create({
                uid: state.info.uid,
                hn: state.info.hostname,
            });
            if (response?.hasRating != null) {
                commit('SetHasRating', response.hasRating);
            }
            state.serviceApi.setAuthToken(response.token);
            return true;
        } catch (err: any) {
            logger.info('Error getting KSConnect token:', err);
            commit('SetEnabled', false);
            return false;
        }
    },
    GetProductKeys: async ({ state, commit }: { state: KSConnect; commit: any }, product: ProductTypes) => {
        if (state.serviceApi == null) {
            logger.info('Service API not set');
            commit('SetEnabled', false);
            return;
        }
        try {
            return await state.serviceApi.getProductKeys(product);
            // logger.info(`Product ${product} keys:`, result);
        } catch (err: any) {
            logger.info('Error getting product key:', err);
            commit('SetEnabled', false);
            commit('SetServiceError', 'Eroare la obtinerea cheilor de produs de pe dispozitv');
            return null;
        }
    },
    CheckDeviceCompatibility: ({ state }: { state: KSConnect; commit: any }, compatibilityList: Array<Record<string, any>>) => {
        if (compatibilityList == null) {
            logger.info('Compatibility list not set');
            return false;
        }
        if (state?.info?.os_caption == null) {
            logger.info('OS caption not set');
            return false;
        }
        logger.info('Checking compatibility for:', state.info.os_caption);
        const currentDevice = state.info.os_caption.replace('Microsoft', '').toLowerCase();
        return compatibilityList.some((device) => {
            // logger.info(
            //     'Checking compatibility for:',
            //     device?.compatibility?.codename,
            //     'with:',
            //     currentDevice,
            //     '. Result:',
            //     currentDevice.includes(device?.compatibility?.codename.toLowerCase())
            // );
            const codename = device?.compatibility?.codename.toLowerCase();
            const words = codename.split(' ');
            // For procuts that are compatible with any variation of a version (Windows 10 Pro, Windows 11 Home N, etc.)
            if (words.length < 3) {
                return currentDevice.includes(codename);
            }
            return currentDevice === codename;
        });
    },
    GetInstalledProducts: async ({ state, commit }: { state: KSConnect; commit: any }, product: string) => {
        if (state.serviceApi == null) {
            logger.info('Service API not set');
            commit('SetEnabled', false);
            return;
        }
        try {
            const result = await state.serviceApi.getInstalledProducts(product.toLowerCase());
            logger.info('Installed products for', product, ': ', result);
            commit('SetInstalledProducts', { product: product, products: result });
        } catch (err: any) {
            logger.info('Could not get installed products:', err);
            commit('SetEnabled', false);
            return null;
        }
    },

    CheckProductCompatibility: async (
        { state, commit, dispatch }: { state: KSConnect; commit: any; getters: any; dispatch: any },
        { product, pid }: { product: OrderProduct['data']; pid: number }
    ) => {
        try {
            //Check if product is already in available products
            if (state.productsAvailable.some((p: any) => p.pid == pid)) {
                logger.info('Product already in available products');
                return;
            }

            if (product?.products_compatibilities == null) {
                logger.info(`No product compatibilities found for ${product.name}`);
                return;
            }
            if (product?.products_appconfig == null) {
                logger.info(`No product appconfig found for ${product?.name}`);
                return;
            }
            if (state.deviceCompatible == false) {
                logger.info(`Device is not compatible with KSConnect for ${product.name}`);
                return;
            }

            // Extract necessary product data
            const { pType: productType, codeName: productCodeName, canBeInstalled } = product.products_appconfig;
            const productCompatibility = await dispatch('CheckDeviceCompatibility', product.products_compatibilities);
            let installedApp: string | undefined;
            let installationStatus: ProductInstalledStatuses = ProductInstalledStatuses.NOT_INSTALLED;

            productInstallCheck: if (canBeInstalled && productType !== ProductTypes.WINDOWS && productType) {
                if (!state.installedProducts[productType].productsFetched) {
                    await dispatch('GetInstalledProducts', productType);
                }
                const installedProducts = state.installedProducts[productType]?.list || [];

                if (installedProducts.length === 0) {
                    break productInstallCheck;
                }

                // Find the installed app and set the installation status
                installedApp = findInstalledApp(installedProducts, productCodeName);
                installationStatus = getInstallationStatus(installedApp, installedProducts);
            }
            if (installedApp != null) {
                logger.info(`Installed ${productCodeName} found:`, installedApp);
            }
            commit('AddAvailableProduct', {
                pid,
                type: productType,
                name: product?.name,
                isCompatibleWithDevice: productCompatibility,
                canBeInstalled: product?.products_appconfig?.canBeInstalled,
                canBeActivated: product?.products_appconfig?.canBeActivated,
                installedApp: installedApp,
                installationStatus: installationStatus,
                codeName: productCodeName,
                odtId: product?.products_appconfig?.odtId,
            });
        } catch (err: any) {
            logger.info('Error checking product compatibility:', err);
            return;
        }
    },

    CheckProductActivationStatus: async (
        {
            state,
            commit,
            dispatch,
            rootGetters,
        }: {
            state: KSConnect;
            commit: any;
            dispatch: any;
            rootGetters: any;
        },
        pid: number
    ) => {
        const ksProduct = state.productsAvailable.find((product: any) => product.pid == pid);
        if (ksProduct == null) {
            logger.error('Product not found in available products', pid);
            return;
        }
        const orderProduct = rootGetters['orders/products'].find((op: any) => op.pid == pid);
        if (orderProduct == null) {
            logger.error('Product not found in order products', pid);
            return;
        }

        const productKeys = await dispatch('GetProductKeys', ksProduct.type);
        commit('SetInstalledProductKeys', { productType: ksProduct.type, keys: productKeys });
    },
    ActivationWizardKeyCheck: async ({
        getters,
        rootGetters,
    }: {
        state: KSConnect;
        commit: any;
        getters: any;
        rootGetters: any;
    }) => {
        if (getters.activationWizardSelectedKey == null) {
            logger.error('No key selected');
            return;
        }
        if (getters.activationWizardSelectedPid == null) {
            logger.error('No product selected');
            return;
        }
        const orderProduct: OrderProduct = rootGetters['orders/products'].find(
            (op: any) => op.pid == getters.activationWizardSelectedPid
        );
        const key = orderProduct?.keys?.find((k) => k.id == getters.activationWizardSelectedKey);

        if (key == null) {
            logger.error('Key not found in order products');
            return;
        }

        try {
            return await feathersClient.service('cdkeys_check').create({ key: key.used_key.cdkey.cdkey, action: 'checkKey' });
        } catch (err: any) {
            logger.error('Error checking key:', err);
            return null;
        }
    },

    ReportKeyForChecking: async ({ getters, rootGetters }: { state: KSConnect; commit: any; getters: any; rootGetters: any }) => {
        try {
            if (getters.activationWizardSelectedKey == null) {
                logger.error('No key selected');
                return;
            }
            if (getters.activationWizardSelectedPid == null) {
                logger.error('No product selected');
                return;
            }
            const orderProduct: OrderProduct = rootGetters['orders/products'].find(
                (op: any) => op.pid == getters.activationWizardSelectedPid
            );
            const key = orderProduct?.keys?.find((k) => k.id == getters.activationWizardSelectedKey);
            if (key == null) {
                logger.error('Key not found in order products');
                return;
            }

            await feathersClient.service('orders_keys_change_request').create({
                usedKeyId: key.used_key.id,
                orderKeyId: key.id,
                notes: 'This request was generated based on KS CONNECT key description check. Based on the checks, this keys is not designed for the product it is being used for.',
                attachment: 'ks_connect_auto_generated.png',
                status: 0,
            });
            return 'DONE';
        } catch (err: any) {
            if (err?.data?.data?.errorCode === 'KEY_CHANGE_REQUEST_EXISTS') {
                return 'ALREADY_REPORTED';
            }
            logger.error('Error reporting key:', err);
            return;
        }
    },
    UpgradeWindows: async ({ state, commit }: { state: KSConnect; commit: any }, { from, to }: Record<string, any>) => {
        if (state.serviceApi == null) {
            logger.info('Service API not set');
            commit('SetEnabled', false);
            return;
        }

        const timer = new ExecutionTimer();
        timer.start();

        commit('SetWindowsUpgradeRunning', true);
        try {
            await state.serviceApi.upgradeWindows(from, to).catch((err: any) => {
                logger.error('Error calling serviceApi.upgradeWindows:', err.message);
                throw new Error('Procesul a esuat. Te rugam sa contactezi suportul tehnic.');
            });
            if (timer.isTooQuick(10_000)) {
                throw new Error('Procesul s-a terminat prea repede. Te rugam sa contactezi suportul tehnic.');
            }
        } catch (err: any) {
            logger.error('Error upgrading windows:', err.message);
            commit('SetWindowsUpgradeError', err.message);
        } finally {
            commit('SetWindowsUpgradeRunning', false);
        }
    },

    ActivationWizardCleanInstalledKeys: async ({
        state,
        commit,
        getters,
    }: {
        state: KSConnect;
        commit: any;
        getters: any;
        rootGetters: any;
    }) => {
        if (state.serviceApi == null) {
            logger.info('Service API not set');
            commit('SetEnabled', false);
            return commit('UpdateActivationStep', {
                name: 'cleanInstalledKeys',
                description: 'Serviciul KSConnect nu este disponibil',
                error: true,
            });
        }
        if (getters.activationWizardSelectedKey == null) {
            logger.error('No key selected');
            return commit('UpdateActivationStep', {
                name: 'cleanInstalledKeys',
                description: 'Nu a fost selectat nicio cheie de produs valida',
                error: true,
            });
        }
        if (getters.activationWizardSelectedPid == null) {
            logger.error('No product selected');
            return commit('UpdateActivationStep', {
                name: 'cleanInstalledKeys',
                description: 'Nu a fost selectat niciun produs',
                error: true,
            });
        }

        const selectedProduct: ProductReadyForActivation = getters.productsReadyForActivation.find(
            (op: ProductReadyForActivation) => op.pid == getters.activationWizardSelectedPid
        );
        if (selectedProduct == null) {
            return commit('UpdateActivationStep', {
                name: 'cleanInstalledKeys',
                description: 'Nu a fost gasit produsul selectat',
                error: true,
            });
        }
        if (selectedProduct.type == null || selectedProduct.type == '') {
            return commit('UpdateActivationStep', {
                name: 'cleanInstalledKeys',
                description: 'Nu a fost gasit tipul produsului',
                error: true,
            });
        }

        try {
            const result: { failedKeys: string[]; removedKeys: string[] } = await state.serviceApi.cleanInstalledKeys(
                selectedProduct.type,
                getters.activationWizardUninstallActiveKeys
            );
            let message = 'Nu am gasit nicio cheie de produs de sters pe dispozitiv';
            let error = false;
            checkContext: {
                if (result?.failedKeys == null || result?.removedKeys == null) {
                    message = 'Nu a fost posibil sa se curete cheile de produs';
                    error = true;
                    break checkContext;
                }
                if (result?.failedKeys.length > 0) {
                    message = `Urmatoarele chei de produs nu au putut fi sterse: ${result.failedKeys.join(
                        ', '
                    )}. Chei de produs sterse: ${result.removedKeys.length}`;
                    break checkContext;
                }

                if (result?.failedKeys.length == 0 && result?.removedKeys.length > 0) {
                    message = 'Urmatoarele chei de produs au fost sterse: ' + result.removedKeys.join(', ');
                    break checkContext;
                }
            }
            commit('UpdateActivationStep', {
                name: 'cleanInstalledKeys',
                completed: true,
                description: message,
            });
            commit('SetActivationProgressStep', 3);
        } catch (err: any) {
            logger.error('Error checking key:', err);
            return commit('UpdateActivationStep', {
                name: 'cleanInstalledKeys',
                error: true,
                description: 'Eroare la curatarea cheilor instalate',
            });
        }
    },

    ActivationWizardInstallProductKey: async ({
        state,
        commit,
        getters,
        rootGetters,
    }: {
        state: KSConnect;
        commit: any;
        getters: any;
        rootGetters: any;
    }) => {
        if (state.serviceApi == null) {
            logger.info('Service API not set');
            commit('SetEnabled', false);
            return commit('UpdateActivationStep', {
                name: 'installProductKey',
                description: 'Serviciul KSConnect nu este disponibil',
                error: true,
            });
        }
        if (getters.activationWizardSelectedKey == null) {
            logger.error('No key selected');
            return commit('UpdateActivationStep', {
                name: 'installProductKey',
                description: 'Nu a fost selectat nicio cheie de produs valida',
                error: true,
            });
        }
        if (getters.activationWizardSelectedPid == null) {
            logger.error('No product selected');
            return commit('UpdateActivationStep', {
                name: 'installProductKey',
                description: 'Nu a fost selectat niciun produs',
                error: true,
            });
        }

        let selectedKey: OrderProductKey | null = null;
        mainFor: for (const product of rootGetters['orders/products']) {
            for (const key of product?.keys || []) {
                if (key.id == getters.activationWizardSelectedKey) {
                    selectedKey = key;
                    break mainFor;
                }
            }
        }

        if (selectedKey == null) {
            return commit('UpdateActivationStep', {
                name: 'installProductKey',
                description: 'Cheia selectata nu a putut fi gasita',
                error: true,
            });
        }
        const {
            used_key: {
                cdkey: { cdkey },
            },
        } = selectedKey;

        try {
            await state.serviceApi.installProductKey(cdkey);
            commit('UpdateActivationStep', {
                name: 'installProductKey',
                completed: true,
                description: 'Cheia de produs a fost instalata cu succes',
            });
            commit('SetActivationProgressStep', 4);
        } catch (error: any) {
            logger.error('Error installing key:', error);
            return commit('UpdateActivationStep', {
                name: 'installProductKey',
                description: 'Eroare la instalarea cheii de produs',
                error: true,
            });
        }
    },

    ActivationWizardActivateKeyOnline: async ({
        state,
        commit,
        getters,
        rootGetters,
    }: {
        state: KSConnect;
        commit: any;
        getters: any;
        rootGetters: any;
    }) => {
        if (state.serviceApi == null) {
            logger.info('Service API not set');
            commit('SetEnabled', false);
            return commit('UpdateActivationStep', {
                name: 'onlineActivation',
                description: 'Serviciul KSConnect nu este disponibil',
                error: true,
            });
        }
        if (getters.activationWizardSelectedKey == null) {
            logger.error('No key selected');
            return commit('UpdateActivationStep', {
                name: 'onlineActivation',
                description: 'Nu a fost selectat nicio cheie de produs valida',
                error: true,
            });
        }
        if (getters.activationWizardSelectedPid == null) {
            logger.error('No product selected');
            return commit('UpdateActivationStep', {
                name: 'onlineActivation',
                description: 'Nu a fost selectat niciun produs',
                error: true,
            });
        }

        let selectedKey: OrderProductKey | null = null;
        mainFor: for (const product of rootGetters['orders/products']) {
            for (const key of product?.keys || []) {
                if (key.id == getters.activationWizardSelectedKey) {
                    selectedKey = key;
                    break mainFor;
                }
            }
        }

        if (selectedKey == null) {
            return commit('UpdateActivationStep', {
                name: 'onlineActivation',
                description: 'Cheia selectata nu a putut fi gasita',
                error: true,
            });
        }
        const {
            used_key: {
                cdkey: { cdkey },
            },
        } = selectedKey;

        try {
            const result = await state.serviceApi.activateKeyOnline(cdkey);
            console.log(result);
            commit('UpdateActivationStep', {
                name: 'onlineActivation',
                completed: true,
                description: 'Cheia de produs a fost activata cu success',
            });
            commit('SetActivationProgressStep', 5);
        } catch (error: any) {
            logger.error('Error activating key online:', error);
            //TODO: Maybe check more here and only if the key was not activated because of code C008 then go to alternative activation
            commit('UpdateActivationStep', {
                name: 'onlineActivation',
                description: 'Nu s-a putut activa produsul online',
                completed: true,
                skipped: true,
            });
            return commit('SetActivationProgressStep', 5);
        }
    },

    ActivationWizardActivateKeyAlternative: async (
        {
            state,
            commit,
        }: {
            state: KSConnect;
            dispatch: any;
            commit: any;
            getters: any;
            rootGetters: any;
        },
        { cid, key }: { cid: string; key: string }
    ) => {
        try {
            await state.serviceApi?.activateKeyAlternative(key, cid);
            commit('UpdateActivationStep', {
                name: 'alternativeActivation',
                completed: true,
                description: 'Cheia de produs a fost activata cu success',
            });
            commit('SetActivationProgressStep', 6);
        } catch (error: any) {
            logger.error('Error activating key online:', error);
            //TODO: Maybe check more here and only if the key was not activated because of code C008 then go to alternative activation
            commit('UpdateActivationStep', {
                name: 'alternativeActivation',
                description: 'Nu s-a putut activa produsul alternativ',
                error: true,
            });
        }
    },

    ActivationWizardCleanup: async ({ commit }: { state: KSConnect; commit: any }) => {
        //TODO: Do things here
        return commit('UpdateActivationStep', {
            name: 'cleanup',
            completed: true,
            description: 'Activarea a fost finalizata cu success',
        });
    },
    ActivationWizardActivationChecks: async ({ state, commit }: { state: KSConnect; commit: any }, stepName: string) => {
        if (state.serviceApi == null) {
            logger.info('Service API not set');
            commit('SetEnabled', false);
            return (
                commit('UpdateActivationStep', {
                    name: stepName,
                    description: 'Serviciul KSConnect nu este disponibil',
                    error: true,
                }),
                false
            );
        }
        if (getters.activationWizardSelectedKey == null) {
            logger.error('No key selected');
            return (
                commit('UpdateActivationStep', {
                    name: stepName,
                    description: 'Nu a fost selectat nicio cheie de produs valida',
                    error: true,
                }),
                false
            );
        }
        if (getters.activationWizardSelectedPid == null) {
            logger.error('No product selected');
            return (
                commit('UpdateActivationStep', {
                    name: stepName,
                    description: 'Nu a fost selectat niciun produs',
                    error: true,
                }),
                false
            );
        }

        return true;
    },
    StartInstaller: async ({ state, commit }: { state: KSConnect; commit: any }, pid: number) => {
        if (state.serviceApi == null) {
            logger.error('Service API not set');
            commit('SetEnabled', false);
            return commit('SetInstallerError', 'Serviciul KSConnect nu este disponibil'), false;
        }
        const ksProduct = state.productsAvailable.find((product: ProductAvailable) => product.pid == pid);
        if (ksProduct == null) {
            return commit('SetInstallerError', 'Produsul nu a fost gasit in lista de produse disponibile'), false;
        }

        commit('SetInstallerRunning', true);
        try {
            const result = await state.serviceApi?.startInstaller({
                odtId: ksProduct?.odtId as string,
                uninstallPreviousVersions: state.installer.uninstallPreviousVersions,
                lang: state.installer.lang?.llCC as string,
            });
            commit('SetInstallSetupPath', result?.setupPath);
            return true;
        } catch (err: any) {
            // More detailed error handling here
            logger.error('Error starting installer:', err);
            commit('SetInstallerError', 'Nu s-a putut initializa procesul de instalare');
            return false;
        }
    },
    CheckInstallerProgress: async ({ state, commit }: { state: KSConnect; commit: any }) => {
        try {
            const status: InstallerCheckProgress = await state.serviceApi?.checkInstallerProgress();
            switch (status?.step) {
                case 'Downloading':
                    commit('SetInstallerStep', InstallerSteps.DOWNLOAD);
                    if (status?.progress > 0) {
                        commit('SetInstallerProgress', status?.progress);
                    }
                    return true;
                case 'Installing':
                    commit('SetInstallerStep', InstallerSteps.INSTALL);
                    if (status?.progress > 0) {
                        commit('SetInstallerProgress', status?.progress);
                    }
                    return true;
                case 'Completed':
                    commit('SetInstallerStep', InstallerSteps.COMPLETE);
                    return true;
                case 'Error':
                    commit('SetInstallerError', `Eroare intampinata la instalarea produsului:<br>${status.error}`);
                    return false;
                default:
                    commit('SetInstallerError', 'Eroare necunoscuta');
                    return false;
            }
        } catch (err: any) {
            logger.error('Error checking installer progress:', err);
            commit('SetInstallerError', 'Eroare la verificarea progresului instalarii');
            return false;
        }
    },
    RemoveInstallFiles: async ({ state, commit }: { state: KSConnect; commit: any }, pid: number) => {
        if (state.serviceApi == null) {
            logger.error('Service API not set');
            commit('SetEnabled', false);
            return commit('SetInstallerError', 'Serviciul KSConnect nu este disponibil'), false;
        }
        const ksProduct = state.productsAvailable.find((product: ProductAvailable) => product.pid == pid);
        if (ksProduct == null) {
            return commit('SetInstallerError', 'Produsul nu a fost gasit in lista de produse disponibile'), false;
        }

        try {
            await sleep(5000);
            await state.serviceApi?.removeInstallFiles({
                odtId: ksProduct?.odtId as string,
                lang: state.installer.lang?.llCC as string,
            });
            return true;
        } catch (err: any) {
            logger.error('Error removing install files:', err);
            commit(
                'SetInstallerError',
                `Nu s-au putut sterge fisierele de instalare. Le puteti sterge manual din folderul de instalare (${state.installer.setupPath})`
            );
            return false;
        }
    },
};
