<template>
    <div
        class="flex flex-row justify-content-start align-items-center gap-2"
        :class="{
            'text-green-500': !keyIsActivated && !keyIsActiveOnDevice,
            'text-red-500': keyIsActivated || keyIsActiveOnDevice,
        }"
    >
        <i
            :class="{
                pi: true,
                'pi-check': !keyIsActivated && !keyIsActiveOnDevice,
                'pi-times': keyIsActivated || keyIsActiveOnDevice,
            }"
        ></i>
        <div v-if="keyIsActivated || keyIsActiveOnDevice">
            Cheia a fost activata anterior <span v-if="keyIsActivated">(Cod de confirmare obtinut)</span>
        </div>
        <div v-else>Cheia nu a fost activata anterior</div>
    </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue';
import { useKeySelected } from '@/composables/ksconnect/use-key-selected';

const props = defineProps<{
    keyId: number;
}>();

const { keyId } = toRefs(props);

const { selectedKey, keyIsActiveOnDevice } = useKeySelected(keyId.value);

const keyIsActivated = computed(() => {
    return selectedKey.value?.cid != null;
});
</script>

<style scoped></style>
