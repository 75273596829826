<template>
    <Dialog
        v-model:visible="visible"
        header="Acord termeni pentru retur"
        position="center"
        class="w-11 md:w-6 lg:w-5"
        :modal="true"
        :draggable="false"
        :closable="false"
    >
        <div>
            <span class="text-surface-500 dark:text-surface-400 block my-3"
                >Pentru a accesa comanda este necesar sa citesti si sa confirmi termenii si conditiile de mai jos.</span
            >
            <div class="flex items-center align-items-center gap-2">
                <Checkbox :disabled="isLoading" v-model="confirm" inputId="confirm" name="confirm" :value="1" />
                <label for="confirm" class="ml-2 font-semibold cursor-pointer line-height-2">
                    Confirm ca accept
                    <a
                        target="_blank"
                        class="text-yellow-600"
                        href="https://keysoutlet.ro/pages/politica-retur#exceptions"
                        >termenii si conditiile</a
                    >, iar dupa accesarea comenzii, produsele devin nerambursabile, exceptand situatiile mentionate in
                    <a
                        href="https://keysoutlet.ro/pages/politica-retur#exceptions"
                        target="_blank"
                        class="text-yellow-600"
                        >termeni</a
                    >.
                </label>
            </div>
        </div>
        <template #footer>
            <Button
                label="Acceseaza comanda"
                :loading="isLoading"
                :disabled="confirm != 1"
                severity="primary"
                autofocus
                @click="confirmToS"
            />
            <Button label="Nu sunt de acord" :loading="isLoading" @click="refuseToS" text severity="danger" autofocus />
        </template>
    </Dialog>
</template>
<script setup lang="ts">
import { computed, ref, inject } from 'vue';
import feathersClient from '@/feathers-client';
import { useStore } from 'vuex';
import { useAuth } from '@/composables/use-auth';
import { handleRequestError } from '@/utils/error-handlers';

const store = useStore();
const auth = useAuth(store);
const OrdersControlAPI = feathersClient.service('/orders/control');
const reportSentry: any = inject('reportSentry');
const visible = true;
const confirm = ref(0);
const isLoading = ref(false);

const orderControlId = computed(() => store.getters['auth/orderControlId']);
const confirmToS = async () => {
    isLoading.value = true;

    try {
        const result = await OrdersControlAPI.patch(orderControlId.value, { action: 'tos_agreement' });
        if (result?.tos_confirm_date) {
            store.commit('auth/SET_HAS_TOS_CONFIRMED', result);
        }
    } catch (err) {
        console.error(err);
        handleRequestError(err);
        reportSentry(err, {
            extra: { fn: 'confirmToS', params: { action: 'tos_agreement' } },
        });
    } finally {
        isLoading.value = false;
    }
};

const refuseToS = () => auth.logout();
</script>
<style scoped>
.code-input-response {
    box-shadow: none;
    border-radius: 3px;
}
.p-button-large {
    padding: 0.8rem 1.2rem;
}
</style>
