<template>
    <div
        class="flex flex-row justify-content-start align-items-center gap-2"
        :class="{
            'text-green-500': !keyHasDeviceAssociated && !keyIsActiveOnDevice,
            'text-orange-500': keyHasDeviceAssociated || keyIsActiveOnDevice,
        }"
    >
        <i
            :class="{
                pi: true,
                'pi-check': !keyHasDeviceAssociated && !keyIsActiveOnDevice,
                'pi-exclamation-circle': keyHasDeviceAssociated || keyIsActiveOnDevice,
            }"
        ></i>
        <div v-if="keyIsActiveOnDevice" class="font-medium">Cheia este activa pe acest dispozitiv</div>
        <div v-else-if="keyHasDeviceAssociated">
            Cheia de produs este asociata unui alt dispozitiv:
            <span class="font-semibold">{{ keyDevice }}</span>
        </div>
        <div v-else>Cheia nu este asociata unui alt dispozitiv</div>
    </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';
import { useKeySelected } from '@/composables/ksconnect/use-key-selected';

const props = defineProps<{
    keyId: number;
}>();

const { keyId } = toRefs(props);

const { keyHasDeviceAssociated, keyDevice, keyIsActiveOnDevice } = useKeySelected(keyId.value);
</script>

<style scoped></style>
