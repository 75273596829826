import { Installer, InstallerLanguage, InstallerSteps, ProductAvailable } from '@/store/ksconnect';
import { useConfirm } from 'primevue/useconfirm';
import { computed, onBeforeUnmount, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { useProductInstallationStatus } from './use-product-installation-status';

export function useInstaller(pid: number) {
    const { isProductInstalled, isProductInstalledWithOthers } = useProductInstallationStatus(pid);
    const store = useStore();
    const toast = useToast();
    const confirm = useConfirm();
    const isFilesRemoving = ref<boolean>(false);

    const progressCheckInterval = ref<any>(null);

    const isProductInstallerDialogVisible = ref<boolean>(false);
    const openInstallerDialog = () => {
        isProductInstallerDialogVisible.value = true;
    };

    const canBeInstalled = computed(() => {
        return store.getters['ksconnect/productsAvailable'].find(
            (p: ProductAvailable) => p.pid == pid && p.canBeInstalled && p?.odtId != null
        );
    });
    const isInstallerRunning = computed(() => store.getters['ksconnect/isInstallerRunning']);
    const progress = computed(() => store.getters['ksconnect/installerProgress']);
    const currentStep = computed(() => store.getters['ksconnect/installerStep']);
    const installerError = computed(() => store.getters['ksconnect/installerError']);
    const setupPath = computed<Installer['setupPath']>(() => store.getters['ksconnect/installerSetupPath']);
    const timeProgress = computed(() => store.getters['ksconnect/installerTimeProgress']);

    const productToBeInstalled = computed<ProductAvailable>(() => {
        return store.getters['ksconnect/productsAvailable'].find((p: ProductAvailable) => p.pid == pid);
    });

    const uninstallPreviousVersions = computed({
        get: () => {
            return [store.getters['ksconnect/installerUninstallPreviousVersions']];
        },
        set: (value: any) => {
            // This fucking shit doesn't make any sens, but it's Primevue's fault
            if (Array.isArray(value)) {
                value = value.filter((v: any) => v);
                if (value.length == 0) {
                    value = ['0', '0'];
                }
            }

            store.commit('ksconnect/SetInstallerUninstallPreviousVersions', value[1]);
        },
    });

    const selectedLanguage = computed({
        get: () => {
            return store.getters['ksconnect/installerSelectedLanguage'];
        },
        set: (val: InstallerLanguage) => {
            store.commit('ksconnect/SetInstallerSelectedLanguage', val);
        },
    });

    const closeInstallerDialog = () => {
        isProductInstallerDialogVisible.value = false;
        if (currentStep.value === InstallerSteps.COMPLETE || installerError.value != null) {
            window.location.reload();
        }
        store.commit('ksconnect/CloseInstaller');
    };

    const clearSelectedLanguage = () => {
        store.commit('ksconnect/SetInstallerSelectedLanguage', null);
    };

    const removeInstallFiles = async () => {
        isFilesRemoving.value = true;
        await store.dispatch('ksconnect/RemoveInstallFiles', pid);
        isFilesRemoving.value = false;
        toast('Fisierele au fost sterse');
    };

    const startInstaller = async () => {
        if (isInstallerRunning.value) {
            return toast.error('Instalarea ruleaza deja');
        }

        const confirmation = await new Promise((resolve, reject) => {
            confirm.require({
                group: 'headless',
                message:
                    'Procesul de descarcare si instalare poate dura pana la 60 minute, in functie de viteza conexiunii la internet si performantele calculatorului. Doresti sa continui?',
                header: 'Confirmare',
                accept: () => {
                    resolve(true);
                    confirm.close();
                },
                reject: () => {
                    resolve(false);
                    confirm.close();
                },
            });
        }).catch(() => false);
        if (!confirmation) {
            return false;
        }

        const status = await store.dispatch('ksconnect/StartInstaller', pid);
        if (status === false) {
            return;
        }

        setProgressCheckInterval();
    };

    const setProgressCheckInterval = () => {
        progressCheckInterval.value = setInterval(async () => {
            if (timeProgress.value > 7200) {
                clearInterval(progressCheckInterval.value);
                store.commit('ksconnect/SetInstallerTimeProgres', null);
                return store.commit(
                    'ksconnect/SetInstallerError',
                    'Procesul de instalare a durat prea mult. Te rog sa incerci din nou.'
                );
            }

            const status = await store.dispatch('ksconnect/CheckInstallerProgress', pid);
            if (status === false || currentStep.value === InstallerSteps.COMPLETE) {
                clearInterval(progressCheckInterval.value);
                return (progressCheckInterval.value = null);
            }
            store.commit('ksconnect/SetInstallerTimeProgres', (timeProgress.value ?? 0) + 5);
        }, 5000);
    };

    onBeforeUnmount(() => {
        if (progressCheckInterval.value != null) {
            clearInterval(progressCheckInterval.value);
        }
    });

    return {
        isProductInstalled,
        isProductInstalledWithOthers,
        canBeInstalled,
        isProductInstallerDialogVisible,
        openInstallerDialog,
        closeInstallerDialog,
        startInstaller,
        isInstallerRunning,
        uninstallPreviousVersions,
        selectedLanguage,
        clearSelectedLanguage,
        progress,
        currentStep,
        installerError,
        productToBeInstalled,
        setupPath,
        removeInstallFiles,
        isFilesRemoving,
    };
}
