import { computed } from 'vue';
import { useStore } from 'vuex';
import {
    InstalledKey,
    LicenseStatus,
    ProductAvailable,
    ProductInstalledStatuses,
    ProductReadyForActivation,
} from '@/store/ksconnect';
import { useWindowsUpgrade } from './use-windows-upgrade';
import { OrderProduct } from '@/store/services/orders';

export function useProductInstallationStatus(pid: number) {
    const store = useStore();
    const { productIsWindows } = useWindowsUpgrade(pid);

    const productsAvailable = computed<ProductAvailable[]>(() => store.getters['ksconnect/productsAvailable']);

    const isProductInstalled = computed(() => {
        if (productIsWindows.value) {
            return true;
        }
        const product = productsAvailable.value.find((product: Record<string, any>) => product.pid === pid);
        if (product == null || product?.installationStatus == null) {
            return false;
        }
        return product.installationStatus === ProductInstalledStatuses.INSTALLED;
    });

    const isProductInstalledWithOthers = computed(() => {
        if (productIsWindows.value) {
            return false;
        }
        const product = productsAvailable.value.find((product: Record<string, any>) => product.pid === pid);
        if (product == null || product?.installationStatus == null) {
            return false;
        }
        return product.installationStatus === ProductInstalledStatuses.INSTALLED_WITH_OTHERS;
    });

    const isProductNotInstalled = computed(() => {
        if (productIsWindows.value) {
            return false;
        }
        const product = productsAvailable.value.find((product: Record<string, any>) => product.pid === pid);
        if (product == null || product?.installationStatus == null) {
            return false;
        }
        return product.installationStatus === ProductInstalledStatuses.NOT_INSTALLED;
    });

    const otherProductsInstalled = computed(() => {
        if (productIsWindows.value) {
            return false;
        }
        const product = productsAvailable.value.find((product: Record<string, any>) => product.pid === pid);
        if (product == null || product?.installationStatus == null) {
            return false;
        }
        return product.installationStatus === ProductInstalledStatuses.OTHERS_INSTALLED;
    });

    const productActivatedOnDevice = computed(() => {
        const orderProduct: OrderProduct | null = store.getters['orders/products'].find((op: OrderProduct) => op.pid == pid);
        if (orderProduct == null) {
            return false;
        }
        return orderProduct?.keys?.some((key) => {
            const currentPartialKey = key?.used_key?.cdkey?.cdkey.split('-').at(-1);
            return store.getters['ksconnect/installedKeys'].some(
                (key: InstalledKey) =>
                    key.partial_product_key === currentPartialKey && key.license_status == LicenseStatus.LICENSED
            );
        });
    });

    return {
        isProductInstalled,
        isProductInstalledWithOthers,
        isProductNotInstalled,
        otherProductsInstalled,
        productActivatedOnDevice,
    };
}
