<template>
    <div class="p-text-secondary text-lg block mb-3">
        Alege cheia pentru activarea produsului <span class="font-semibold">{{ selectedProduct?.data?.name }}</span>
    </div>

    <div class="flex flex-column w-full align-items-start justify-content-start">
        <template v-if="availableKeys == null || availableKeys.length == 0">
            <Message class="w-full" :closable="false" severity="warn">Nu exista chei de activare disponibile.</Message>
        </template>
        <template v-else>
            <div class="w-full flex flex-column justify-content-center align-items-center gap-5">
                <!-- Selectable Keys List -->
                <div class="w-full flex flex-column gap-3" v-if="selectableKeys?.length > 0">
                    <h3 class="mb-0">Chei disponibile:</h3>
                    <div class="keys-container">
                        <div
                            v-for="(k, _) in selectableKeys"
                            :key="k.id"
                            class="flex flex-row align-items-center available-key gap-2 py-2 pl-3 pr-5 border-round cursor-pointer py-2 my-2"
                            :class="{
                                'border-1': selectedKey != k.id,
                                'border-2': selectedKey == k.id,
                                'surface-border': selectedKey != k.id,
                                'border-primary': selectedKey == k.id,
                            }"
                        >
                            <RadioButton v-model="selectedKey" :inputId="String(k.id)" :name="String(k.id)" :value="k.id" />
                            <label
                                :for="String(k.id)"
                                class="w-full ml-2 flex flex-row align-items-center justify-content-start cursor-pointer"
                            >
                                <div class="flex flex-column justify-content-center align-items-start gap-1">
                                    <div class="font-medium">
                                        {{ k.key }}
                                    </div>
                                    <div>
                                        <ChecksKeyActivated :keyId="k.id"></ChecksKeyActivated>
                                        <ChecksKeyAssociation :keyId="k.id"></ChecksKeyAssociation>
                                        <ChecksKeyReported :keyId="k.id"></ChecksKeyReported>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                <!-- Reported or Unavailable Keys List -->
                <div class="w-full flex flex-column gap-3" v-if="unavailableKeys?.length > 0">
                    <h3 class="mb-0">Chei indisponibile:</h3>
                    <div class="keys-container">
                        <div
                            v-for="(k, _) in unavailableKeys"
                            :key="k.id"
                            class="flex flex-column align-items-start justify-content-center unavailable-key gap-2 py-2 pl-3 pr-5 border-round border-1 surface-border py-2 my-2"
                        >
                            <div class="font-medium text-red-500">
                                {{ k.key }}
                            </div>
                            <div>
                                <ChecksKeyActivated :keyId="k.id"></ChecksKeyActivated>
                                <ChecksKeyAssociation :keyId="k.id"></ChecksKeyAssociation>
                                <ChecksKeyReported :keyId="k.id"></ChecksKeyReported>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import ChecksKeyAssociation from '@/components/KSConnect/Checks/KeyAssociation.vue';
import ChecksKeyReported from '@/components/KSConnect/Checks/KeyReported.vue';
import ChecksKeyActivated from '@/components/KSConnect/Checks/KeyActivated.vue';
import { useKeySelected } from '@/composables/ksconnect/use-key-selected';
import { ProductTypes } from '@/store/ksconnect';
import InlineMessage from 'primevue/inlinemessage';

const store = useStore();

const selectedPid = computed(() => store.getters['ksconnect/activationWizardSelectedPid']);
const ordersProducts = computed(() => store.getters['orders/products']);
const selectedProduct = computed(() => {
    return ordersProducts.value.find((product: Record<string, any>) => product?.pid == selectedPid.value);
});

const selectedKey = computed({
    get() {
        return store.getters['ksconnect/activationWizardSelectedKey'] || 0;
    },
    set(value: any) {
        store.commit('ksconnect/SetActivationWizardKey', value);
    },
});

const availableKeys = computed<
    {
        id: number;
        changeRequest: boolean;
        device: string | null;
        cid?: string | null;
        key: string;
    }[]
>(() => {
    if (!selectedPid.value) {
        return [];
    }

    const orderProduct = ordersProducts.value.find((product: Record<string, any>) => product?.pid == selectedPid.value);

    if (!orderProduct) {
        return [];
    }

    return orderProduct?.keys?.map((key: Record<string, any>) => ({
        id: key.id,
        changeRequest: key.orders_keys_change_request != null,
        device: key.orders_keys_device?.device,
        cid: key.orders_keys_cid?.cid,
        key: key.used_key?.cdkey?.cdkey,
    }));
});

// Computed properties for selectable and reported keys
const selectableKeys = computed(() => availableKeys.value.filter((k) => !keyIsUnavailable(k.id)));
const unavailableKeys = computed(() => {
    const keys = availableKeys.value.filter((k) => keyIsUnavailable(k.id));
    // sort the keys. put the key that is active on device first

    keys.sort((a, b) => {
        const { keyIsActiveOnDevice: keyAIsActiveOnDevice } = useKeySelected(a.id);
        const { keyIsActiveOnDevice: keyBIsActiveOnDevice } = useKeySelected(b.id);
        if (keyAIsActiveOnDevice.value && !keyBIsActiveOnDevice.value) {
            return -1;
        } else {
            return 1;
        }
    });
    return keys;
});

const keyIsUnavailable = (keyId: number) => {
    const { keyIsReported: isReported, keyHasDeviceAssociated, keyIsActiveOnDevice, selectedKey } = useKeySelected(keyId);
    return isReported.value || keyHasDeviceAssociated.value || keyIsActiveOnDevice.value || selectedKey.value?.cid != null;
};
</script>

<style scoped>
.keys-container {
    max-height: 400px;
    overflow-y: auto;
}

.available-key,
.unavailable-key {
    width: calc(100% - 20px);
}
</style>
