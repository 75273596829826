import { Ref } from 'vue';
import app from '@/main';
import store from '@/store';
import { useToast } from 'vue-toastification';

const handleRequestError = function (err: any, errContainer?: Ref | undefined): Record<string, unknown> {
    const toast = useToast();
    const handleBadRequestError = () => {
        console.debug(err.errors);
        if (err.errors != null && Object.keys(err.errors).length > 0) {
            for (const key in err.errors) {
                let errorMessage;
                if (typeof err.errors[key] === 'object') {
                    errorMessage = err.errors[key].message || err.errors[key];
                } else {
                    errorMessage = err.errors[key];
                }
                if (errContainer) {
                    errContainer.value.push({ message: errorMessage, id: Date.now() });
                } else {
                    toast.error(errorMessage, { timeout: 5000 });
                }
            }
        } else {
            if (errContainer) {
                errContainer.value.push({ message: err.message, id: Date.now() });
            } else {
                toast.error(err.message, { timeout: 5000 });
            }
        }
    };

    const handleGeneralError = () => {
        if (errContainer) errContainer.value.push({ message: err.message, id: Date.now() });
        else {
            toast.error(err.message, { timeout: 5000 });
        }
    };

    const handleForbiddenError = () => {
        if (errContainer)
            errContainer.value.push({ message: 'Nu ai acces la aceasta functionalitate', id: Date.now() });
        else {
            toast.error('Nu ai acces la aceasta functionalitate', { timeout: 5000 });
        }
    };

    switch (err.name) {
        case 'Forbidden':
            handleForbiddenError();
            break;
        case 'BadRequest':
            handleBadRequestError();
            break;
        case 'GeneralError':
        case 'NotAuthenticated':
        case 'Unprocessable':
        case 'NotFound':
            handleGeneralError();
            break;
        default:
            handleGeneralError();
    }

    return err.data || {};
};

export { handleRequestError };
