<template>
    <Card class="code-input-response shadow-1 sm:w-8 w-10 mt-5">
        <template #content>
            <div class="py-3 md:py-6 flex flex-row align-items-center justify-content-center text-center">
                <div class="icon text-bluegray-200 mr-4">
                    <i class="pi pi-lock" style="font-size: 4rem"></i>
                </div>
                <div class="text text-left w-9 sm:w-6">
                    <div class="title text-xl md:text-2xl text-gray-600 pb-1 font-semibold">INTRODUCETI CODUL</div>
                    <div class="text-base md:text-md text-gray-500">
                        Pentru a vedea comanda, tasteaza
                        <span class="font-semibold">codul din {{ INPUT_LENGTH }} cifre primit prin SMS sau EMAIL</span>
                        in casuta de mai sus
                    </div>
                </div>
            </div>
        </template>
    </Card>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue';
const INPUT_LENGTH = 5;

export default defineComponent({
    setup(props, ctx) {
        return { INPUT_LENGTH };
    },
});
</script>
<style scoped>
.code-input-response {
    box-shadow: none;
    border-radius: 3px;
}
</style>
