<template>
    <Dialog visible modal :closable="false" class="col-10 md:col-8 lg:col-5">
        <ProductInstaller :pid="pid"></ProductInstaller>
        <template #footer>
            <Button
                :disabled="isInstallerRunning && !installerError && currentStep != InstallerSteps.COMPLETE"
                type="button"
                text
                label="Inchide"
                severity="secondary"
                @click="emit('close')"
            ></Button>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { useInstaller } from '@/composables/ksconnect/use-installer';
import { toRefs } from 'vue';
import ProductInstaller from './ProductInstaller.vue';
import { InstallerSteps } from '@/store/ksconnect';
const props = defineProps<{ pid: number }>();
const emit = defineEmits<{
    (e: 'close'): void;
}>();

const { pid } = toRefs(props);

const { isInstallerRunning, installerError, currentStep } = useInstaller(pid.value);
</script>

<style scoped></style>
