<template>
    <Dialog
        :visible="true"
        modal
        header="Informatii produse instalate"
        @update:visible="hideDialog"
        :style="{ width: '50rem' }"
    >
        <div class="line-height-2">
            Pentru a asigura o activare fara probleme, recomandam instalarea versiuni corespunzatoare produselor.
        </div>
        <div class="line-height-2">
            Instalarea impreuna cu alte versiuni ale produsului poate duce la erori de activare.
        </div>
        <p class="font-bold">Pe acest dispozitiv au fost detectate urmatoarele versiuni ale produsului instalate:</p>
        <div v-if="installedProducts.length > 0">
            <ul class="p-0 m-0 px-4">
                <li class="py-1" v-for="(item, index) in installedProducts" :key="index">
                    <div class="flex flex-row justify-content-start align-items-center gap-2">
                        <div>
                            {{ item }}
                        </div>
                        <Tag
                            :severity="isProductCompatible(item) ? 'success' : 'warning'"
                            :value="isProductCompatible(item) ? 'Compatibil' : 'Incompatibil'"
                        ></Tag>
                    </div>
                </li>
            </ul>
        </div>
        <div v-else>Eroare la afisarea produselor instalate</div>
    </Dialog>
</template>

<script setup lang="ts">
import { ProductAvailable, ProductTypes } from '@/store/ksconnect';
import { computed, defineProps, toRefs } from 'vue';
import { useStore } from 'vuex';

const props = defineProps<{
    pid: number;
}>();

const emit = defineEmits<{
    (e: 'hide', value: any): void;
}>();

const store = useStore();
const { pid } = toRefs(props);

const installedProducts = computed(() => {
    const ksProduct = store.getters['orders/products'].find((product: ProductAvailable) => product.pid == pid.value);
    if (!ksProduct) {
        return [];
    }
    const productType: ProductTypes = ksProduct?.data?.products_appconfig?.pType;
    const installedProducts = store.getters['ksconnect/installedProducts'];
    return installedProducts?.[productType]?.list || [];
});

const isProductCompatible = (item: string) => {
    const availableProduct: ProductAvailable = store.getters['ksconnect/productsAvailable'].find(
        (product: ProductAvailable) => product.pid === pid.value
    );
    return availableProduct?.installedApp === item;
};

const hideDialog = () => {
    emit('hide', true);
};
</script>

<style lang="scss" scoped></style>
