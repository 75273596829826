<template>
    <div class="instructions text-lg block mb-3" v-if="!isInstallerRunning">
        <div v-if="!selectedLanguage">
            Alege limba pentru produsul <span class="font-semibold">{{ productToBeInstalled.name }}</span
            >:
        </div>
        <div v-else>
            <span class="font-semibold">{{ productToBeInstalled.name }}</span> va fi instalat in limba selectata si arhitectura
            sistemului de operare instalat.
        </div>
    </div>

    <template v-if="isInstallerRunning">
        <div class="installer-status flex flex-column align-items-center justify-content-center mt-5">
            <template v-if="installerError">
                <i class="pi pi-exclamation-circle text-orange-500 text-6xl mb-3"></i>
            </template>
            <template v-else-if="currentStep === InstallerSteps.COMPLETE">
                <i class="pi pi-check-circle text-green-500 text-6xl mb-3"></i>
            </template>
            <template v-else>
                <ProgressSpinner style="width: 60px; height: 60px" class="status-spinner mb-3" />
            </template>
            <p class="status-message" v-html="installerMessage"></p>
            <div class="font-semibold" v-if="currentStep === InstallerSteps.DOWNLOAD && !installerError">({{ setupPath }})</div>
            <div
                v-if="currentStep === InstallerSteps.COMPLETE && !installerError && !installFilesRemoved"
                class="flex flex-column justify-content-center align-items-center mt-2 gap-2"
            >
                <div class="text-gray-600">Doresti sa stergi fisierele descarcate utilizate la instalarea produsului?</div>
                <div>
                    Calea catre fisiere este: <span class="font-semibold">{{ setupPath }}</span>
                </div>
                <div class="flex flex-row justify-content-center align-items-center gap-2">
                    <Button
                        @click="removeInstallFiles"
                        :loading="isFilesRemoving"
                        label="Sterge fisierele"
                        class="p-button-outlined p-button-secondary"
                        icon="pi pi-trash"
                    />
                </div>
            </div>
        </div>
    </template>

    <template v-if="selectedLanguage && !isInstallerRunning">
        <table class="selected-language-table">
            <tbody>
                <tr>
                    <td>
                        <div class="flex flex-row gap-2 align-items-center justify-content-center">
                            <country-flag :country="getCountryISO(selectedLanguage.llCC)" size="normal" />
                            <span>{{ selectedLanguage.language }}</span>
                        </div>
                    </td>
                    <td>{{ selectedLanguage.llCC }}</td>
                    <td>{{ selectedLanguage.proofing }}</td>
                    <td>
                        <Button
                            outlined
                            severity="secondary"
                            size="small"
                            label="Schimba"
                            @click="clearSelectedLanguage"
                        ></Button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="flex flex-column justify-content-center align-items-center mt-4">
            <!-- We do not want to show this checkbox for now. We can't risk ODT uninstalling other products outside of Offce -->
            <div v-if="false" class="flex flex-row justify-content-center align-items-center mb-3">
                <Checkbox
                    v-model="uninstallPreviousVersions"
                    inputId="uninstallPreviousVersions"
                    name="uninstallPreviousVersions"
                    value="1"
                />
                <label for="uninstallPreviousVersions" class="ml-2 mr-1 cursor-pointer">
                    Dezinstaleaza (daca exista) versiuni anterioare ale produsului inainte de instalare
                    <span class="font-italic font-semibold">(recomandat)</span>.
                </label>
            </div>

            <Message :closable="false" class="w-full m-0" severity="info"
                ><div class="w-full">
                    Inainte de a incepe procesul, asigura-te ca ai o conexiune stabila la internet si ai disponibil
                    <strong>minim 12GB spatiu pe partitia utilizata de Windows (C)</strong>
                </div></Message
            >
            <Button class="install-btn mt-3" label="Instaleaza produsul" severity="primary" @click="startInstaller"></Button>
        </div>
    </template>

    <template v-else-if="!isInstallerRunning">
        <DataTable
            :value="languages"
            v-model:selection="selectedLanguage"
            class="w-full odt-languages-table"
            dataKey="llCC"
            scrollable
            scrollHeight="300px"
            :loading="isLoading"
            selectionMode="single"
        >
            <Column selectionMode="single"></Column>
            <Column field="language" header="Limba">
                <template #body="slotProps">
                    <div class="flex flex-row gap-2 align-items-center">
                        <country-flag :country="getCountryISO(slotProps.data.llCC)" size="normal" />
                        <span>{{ slotProps.data.language }}</span>
                    </div>
                </template>
            </Column>
            <Column field="llCC" header="Cod"></Column>
            <Column field="proofing" header="Limbi de verificare insotite"></Column>
        </DataTable>
    </template>
</template>

<script setup lang="ts">
import feathersClient from '@/feathers-client';
import { handleRequestError } from '@/utils/error-handlers';
import CountryFlag from 'vue-country-flag-next';
import { onMounted, ref, defineProps, toRefs, computed, watch } from 'vue';
import { useInstaller } from '@/composables/ksconnect/use-installer';
import ProgressSpinner from 'primevue/progressspinner';
import { InstallerSteps } from '@/store/ksconnect';

const props = defineProps<{ pid: number }>();
const { pid } = toRefs(props);

const KSConnectODTLanguagesAPI = feathersClient.service('ksconnect_odt_languages');
const languages = ref([]);
const isLoading = ref(false);
const installFilesRemoved = ref(false);

const getCountryISO = (lang: string) => lang.split('-').pop()?.toLowerCase();

const getLanguages = async () => {
    isLoading.value = true;
    try {
        languages.value = await KSConnectODTLanguagesAPI.find({ query: { status: 1 } });
    } catch (err) {
        handleRequestError(err);
    } finally {
        isLoading.value = false;
    }
};

const installerMessage = computed(() => {
    let message = '';
    switch (true) {
        case installerError.value != null:
            message = installerError.value;
            break;

        case currentStep.value === InstallerSteps.DOWNLOAD:
            message = 'Se descarca produsul...';
            break;

        case currentStep.value === InstallerSteps.INSTALL:
            message = 'Se instaleaza produsul...';
            break;

        case currentStep.value === InstallerSteps.COMPLETE:
            message = 'Instalare finalizata.<br>Reincarca pagina pentru a incepe activarea produsului.';
            break;

        default:
            message = 'Se pregateste mediul de instalare...';
    }

    if (
        installerError.value == null &&
        (currentStep.value === InstallerSteps.DOWNLOAD || currentStep.value === InstallerSteps.INSTALL) &&
        progress.value > 0
    ) {
        message += ` ${progress.value}%`;
    }
    return message;
});

const removeInstallFiles = async () => {
    await removeFiles();
    installFilesRemoved.value = true;
};

const {
    startInstaller,
    isInstallerRunning,
    uninstallPreviousVersions,
    selectedLanguage,
    clearSelectedLanguage,
    progress,
    currentStep,
    installerError,
    setupPath,
    removeInstallFiles: removeFiles,
    productToBeInstalled,
    isFilesRemoving,
} = useInstaller(pid.value);

onMounted(getLanguages);
</script>

<style lang="scss" scoped>
.odt-languages-table {
    ::v-deep .p-datatable-table {
        td {
            padding: 0.3rem 0.6rem !important;
        }
    }
}

.cty-flag {
    display: flex;
    align-self: baseline;
}

.selected-language-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    th,
    td {
        border: 1px solid #d9e2ec;
        padding: 0.4rem;
        text-align: center;
    }
    th {
        background-color: #f5f5f5;
    }
    td {
        background-color: #fff;
    }
}

.install-btn {
    padding: 0.8rem 1.4rem;
}

.installer-status {
    .status-message {
        font-size: 1.2rem;
        color: #333;
        text-align: center;
        margin-top: 1rem;
    }
}

.installer-error-icon {
    width: 100px;
    height: 100px;
    color: var(--red-500);
}
</style>
