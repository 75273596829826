import { ServiceApi, useKSConnect } from '@/composables/ksconnect/use-ksconnect';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { initialKSConnectStore } from './state';

export type PID = number;

export enum ProductTypes {
    OFFICE = 'OFFICE',
    WINDOWS = 'WINDOWS',
    VISIO = 'VISIO',
    PROJECT = 'PROJECT',
}

export enum ProductInstalledStatuses {
    NOT_INSTALLED = 0,
    INSTALLED = 1,
    INSTALLED_WITH_OTHERS = 2,
    OTHERS_INSTALLED = 3,
}

export enum ActivationMethods {
    'ONLINE' = 'ONLINE',
    'OFFLINE' = 'OFFLINE',
    'BOTH' = 'ONLINE + OFFLINE',
}

export interface ProductInstallInfo {
    list: string[] | null;
    filterRegex: RegExp;
    productsFetched: boolean;
}

export interface KSConnectInfo {
    hostname: string;
    os_build: string;
    os_caption: string;
    os_version: string;
    uid: string;
}

export interface KSConnectActivationWizard {
    launched: boolean;
    reloadOnClose: boolean;
    step: 1 | 2 | 3 | 4;
    selectedPid: PID | null;
    selectedKey: number | null;
    activationRunning: boolean;
    uninstallActiveKeys: '1' | '0';
    activationProgress: KSConnectActivationWizardProgressStep[];
}
export interface KSConnectActivationWizardProgressStep {
    step: number;
    started: boolean;
    completed: boolean;
    skipped?: boolean;
    error?: boolean;
    name: string;
    description: string;
}

export interface InstalledKey extends ProductKey {
    product: ProductTypes;
}

export interface InstallerLanguage {
    llCC: string;
    language: string;
}

export interface Installer {
    running: boolean;
    step: InstallerSteps | null;
    uninstallPreviousVersions: '1' | '0';
    lang: InstallerLanguage | null;
    error: string | null;
    progress: number | null;
    setupPath: string | null;
    timeProgress: number | null;
}

export enum InstallerSteps {
    'DOWNLOAD' = 1,
    'INSTALL' = 2,
    'COMPLETE' = 3,
}

export interface InstallerCheckProgress {
    step: 'Downloading' | 'Installing' | 'Completed' | 'Error';
    error: string | null;
    progress: number;
}

export interface KSConnect {
    appVersion: string | null;
    hasRating: boolean;
    installedAppVersion: string | null;
    deviceCompatible: boolean; // Is the device compatible with KSConnect
    productsCompatible: boolean; // Is KSConnect is available for this order/products
    error: string | null;
    enabled: boolean; // Is KSConnect is connected and enabled
    setupComplete: boolean; // Is KSConnect Auth setup completed
    serviceUrl: string | null;
    possiblePorts: number[];
    info: null | KSConnectInfo;
    serviceApi: ServiceApi | null;
    productsAvailable: ProductAvailable[];
    activationWizard: KSConnectActivationWizard;
    windowsUpgrade: KSConnectWindowsUpgrade;
    installer: Installer;
    installedKeys: InstalledKey[];
    installedProducts: {
        [P in Exclude<ProductTypes, ProductTypes.WINDOWS>]: ProductInstallInfo;
    };
}

export interface ProductAvailable {
    pid: PID;
    type: ProductTypes;
    name: string;
    isCompatibleWithDevice: boolean;
    canBeInstalled: boolean;
    canBeActivated: boolean;
    installedApp: string | null;
    installationStatus?: ProductInstalledStatuses;
    codeName: string | null;
    odtId: string | null;
}

export enum LicenseStatus {
    'UNLICENSED' = 0,
    'LICENSED' = 1,
    'OUT_OF_BOX_GRACE' = 2,
    'OUT_OF_TOLERANCE_GRACE' = 3,
    'NON_GENUINE_GRACE' = 4,
    'NOTIFICATION' = 5,
    'OUT_OF_BOX' = 6,
}

export enum LicenseStatusReason {
    WINDOWS_ACTIVATED_DIGITAL_ENTITLEMENT = 1074066433, // 'Windows 10 on this device is activated with digital entitlement'
    OUT_OF_BOX_GRACE_ALLOWANCE = 1074065420, // 'This computer is in Out-Of-Box Grace allowance'
    WINDOWS_ACTIVATED_PRODUCT_KEY = 0, // 'Windows 10 on this device is activated with a product key'
    GRACE_PERIOD_EXPIRED = -1073418231, // 'The Software Licensing Service reported that the grace period expired'
    TIME_BASED_VALIDITY_PERIOD = 1074068484, // 'The Software Licensing Service reported that the application is running within the time-based validity period'
    EXTENDED_GRACE_PERIOD_VALID = 1074068486, // 'The Software Licensing Service reported that the application is running within the valid extended grace period'
    SUBSCRIPTION_LICENSING_FAILED_1 = -1073422302, // 'Subscription licensing service failed'
    SUBSCRIPTION_LICENSING_FAILED_2 = -1073415161, // 'Subscription licensing service failed'
    SUBSCRIPTION_LICENSING_FAILED_3 = -1073418220, // 'Subscription licensing service failed'
}

export interface ProductKey {
    iid: string;
    license_status: LicenseStatus;
    license_status_reason: LicenseStatusReason;
    partial_product_key: string;
    product_name: string;
}

export interface ProductReadyForActivation {
    activation_method: ActivationMethods;
    canBeActivated: number;
    canbeInstalled: number;
    codeName: string | null;
    image: string;
    installationStatus: ProductInstalledStatuses;
    installedApp: string | null;
    isCompatibleWithDevice: boolean;
    name: string;
    opid: number;
    pid: PID;
    type?: ProductTypes | '';
}

export interface KSConnectWindowsUpgrade {
    upgradeRunning: boolean;
    upgradeError: string | null;
    windowActive: boolean;
}

export default {
    name: 'ksconnect',
    namespaced: true, // Important so we can merge the stores
    state: initialKSConnectStore(),
    mutations,
    getters,
    actions,
};
