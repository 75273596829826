<template>
    <div class="ks-connect-full-banner input-container mb-6 sm:w-9 w-11">
        <div class="flex flex-row align-items-center justify-content-between">
            <img src="/images/ksconnect_logo_full.png" class="ks-connect-logo" alt="KS Connect logo" />
            <div v-if="serviceError" class="text-red-300 text-lg">
                Aplicatia nu a putut fi initializata: <span class="font-bold">{{ serviceError }}</span>
            </div>
            <div class="text-white text-lg font-semibold" v-else-if="!appIsLatestVersion">
                Aplicatia <span class="text-primary font-semibold">KSConnect</span> necesita actualizare!
            </div>
            <div class="text-white text-lg font-semibold" v-else>
                Instaleaza aplicatia <span class="text-primary font-semibold">KSConnect</span> pentru a gestiona si activa mai
                usor produsele tale!
            </div>

            <template v-if="!serviceError">
                <Button
                    label="Descarca aplicatia"
                    class="shadow-1 p-button-primary text-bluegray-800"
                    @click="openKSConnectDialog"
                />
            </template>
            <template v-else>
                <Button label="Contacteaza-ne" class="shadow-1 p-button-primary text-bluegray-800" @click="openEmail" />
            </template>
        </div>

        <KSConnectDownload v-if="isKSConnectDialogActive" @close="closeKSConnectDialog"></KSConnectDownload>
    </div>
</template>

<script setup lang="ts">
import KSConnectDownload from '@/components/KSConnect/Download/Index.vue';
import { useKSConnectDownload } from '@/composables/ksconnect/use-download';
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const appIsLatestVersion = computed(() => store.getters['ksconnect/appIsLatestVersion']);
const serviceError = computed(() => store.getters['ksconnect/serviceError']);
const openEmail = () => window.open('mailto:tech@keysoutlet.ro', '_blank');

const { openKSConnectDialog, isKSConnectDialogActive, closeKSConnectDialog } = useKSConnectDownload();
</script>

<style lang="scss" scoped>
.ks-connect-full-banner {
    background-color: hsl(0deg 0% 100% / 9%);
    border-radius: 3px;
    backdrop-filter: blur(1px);
    padding: 1.2rem;
}

img.ks-connect-logo {
    width: 100%;
    width: 11rem;
}
</style>
