export class Emitter extends EventTarget {
    on(type: any, listener: any) {
        this.addEventListener(type, listener);
    }

    off(type: any, listener: any) {
        this.removeEventListener(type, listener);
    }

    emit(type: any, detail?: any) {
        const event: any = new Event(type);
        event.detail = detail;
        this.dispatchEvent(event);
    }
}

// Create a singleton instance and export it
const emitter = new Emitter();
export default emitter;
