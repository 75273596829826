export class ExecutionTimer {
    private startTime: number | null = null;
    private endTime: number | null = null;

    // Start the timer
    start(): void {
        this.startTime = Date.now();
    }

    // Stop the timer and return the duration in milliseconds
    stop(): number {
        if (this.startTime === null) {
            throw new Error('Timer was not started.');
        }
        this.endTime = Date.now();
        return this.endTime - this.startTime;
    }

    // Check if the duration is below the minimum expected time
    isTooQuick(minExpectedTime: number): boolean {
        const duration = this.stop();
        return duration < minExpectedTime;
    }

    // Log the result of the time measurement
    logDuration(minExpectedTime: number): void {
        const duration = this.stop();
        if (duration < minExpectedTime) {
            console.warn(`Operation finished too quickly. Duration: ${duration}ms`);
        } else {
            console.log(`Operation finished in ${duration}ms`);
        }
    }
}
