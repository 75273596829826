<template>
    <Dialog :visible="true" :closable="false" modal header="Deinstalare chei active" class="col-10 md:col-4">
        <ul class="line-height-2">
            <li>
                Inainte de instalarea cheii de produs, servicul nostru va dezinstala cheile de produs inactive si de tip
                VLK sau KMS (licente instalate cu ajutorul anumitor aplicatii de tip activator).
            </li>
            <li>
                Aceasta optiune va dezinstala inclusiv cheile de produs identificate pe dispozitiv care sunt activate.
            </li>
            <li>Acest lucru va asigura o buna functionare si recunoastere a licentei instalate ulterior.</li>
            <li>Recomandam ca aceasta optiune sa ramana activata.</li>
        </ul>

        <template #footer>
            <Button type="button" label="Inchide" severity="primary" @click="hideDialog"></Button>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { defineEmits } from 'vue';
const emit = defineEmits<{
    (e: 'close'): void;
}>();

const hideDialog = () => emit('close');
</script>

<style scoped></style>
