import { ref, computed, Ref } from 'vue';
import { useEnv } from './use-env';

export interface Log {
    level: string;
    text: any[];
    time: string;
}

export const useLogger = () => {
    const env = useEnv();
    let logs: Log[] = [];
    const time = () => new Date().toTimeString().slice(0, 8);
    const isEnabled = () =>
        !env.is('NODE_ENV', 'production') || env.is('LOGGING', 'YES') || localStorage.getItem('ocdg') === '042024';

    const getCallerDetails = (stack: string) => {
        const stackLines = stack.split('\n');
        const relevantLine = stackLines[2] || ''; // Adjust this index if necessary
        const match = relevantLine.match(/at\s+(?:.*?\s+)?\(?([^()]+):(\d+):(\d+)\)?/);
        return match ? { file: match[1], line: match[2], column: match[3] } : null;
    };

    const log = (level: string, args: any[], stack: string) => {
        if (!isEnabled()) return;

        const caller = getCallerDetails(stack);
        const location = caller ? `${caller.file}:${caller.line}` : 'unknown';

        logs.push({ level, text: args, time: time() });

        // Define styles and log levels with %c for colors
        const levels: {
            [key: string]: { style: string; label: string };
        } = {
            info: { style: 'color: skyblue;', label: '[INFO]' },
            warn: { style: 'color: orange;', label: '[WARN]' },
            error: { style: 'color: red;', label: '[ERROR]' },
            debug: { style: 'color: green;', label: '[DEBUG]' },
        };

        const { style, label } = levels[level.toLowerCase()] || levels.info;

        // Log to console with styles
        console.log(`%c${label}`, style, ...args, location);
    };

    const info = (...args: any[]) => {
        const stack = new Error().stack || '';
        log('info', args, stack);
    };
    const warn = (...args: any[]) => {
        const stack = new Error().stack || '';
        log('warn', args, stack);
    };
    const error = (...args: any[]) => {
        const stack = new Error().stack || '';
        log('error', args, stack);
    };
    const debug = (...args: any[]) => {
        const stack = new Error().stack || '';
        log('debug', args, stack);
    };

    const clear = () => (logs = []);

    return {
        logs,
        info,
        warn,
        error,
        debug,
        clear,
    };
};
