import { HookContext } from '@feathersjs/feathers';
import feathersClient, { makeAuthPlugin } from '../feathers-client';
import store from '@/store';
import router from '@/router';

import { useAuth } from '@/composables/use-auth';
import { useLogger } from '@/composables/use-logger';
feathersClient.service('authentication/platform').hooks({
    after: {
        create: [
            async (context: HookContext) => {
                const { service, result } = context;
                const auth = useAuth(store);
                if (
                    router.currentRoute.value?.params?.uid &&
                    result.orders_control?.uid &&
                    router.currentRoute.value?.params.uid != result.orders_control?.uid
                ) {
                    return auth.logout(), context;
                }
                if (result?.ksconnectAppVersion != null) {
                    store.commit('ksconnect/SetAppVersion', result.ksconnectAppVersion);
                }

                return context;
            },
        ],
    },
});

export default makeAuthPlugin({
    userService: 'orders/control',
    responseEntityField: 'orders_control',
    entityIdField: 'id',
    getters: {
        orderId: (state: any) => state?.user?.orderId,
        orderControlId: (state: any) => state?.user?.id,
        hasToSConfirmed: (state: any) => state?.user?.tos_agreed,
    },
    mutations: {
        SET_HAS_TOS_CONFIRMED: (state: any, data: Record<string, any>) => {
            state.user.tos_agreed = data.tos_agreed;
        },
    },
});
