<template>
    <Dialog
        :visible="true"
        modal
        header="Versiuni compatibile"
        @update:visible="hideDialog"
        :style="{ width: '40rem' }"
    >
        <div class="mb-4">
            Versiune Windows instalata pe acest dispozitiv:
            <ul class="p-0 m-0 px-4 pt-1">
                <li>{{ currentWindowsVersion }}</li>
            </ul>
        </div>

        <div>Versiuni Windows compatibile cu produsul:</div>
        <div v-if="compatibilityList.length > 0">
            <ul class="p-0 m-0 px-4 pt-1">
                <li class="py-1" v-for="item in compatibilityList" :key="item.id">{{ item?.compatibility?.name }}</li>
            </ul>
        </div>
        <div v-else>Nu exista versiuni compatibile</div>
    </Dialog>
</template>

<script setup lang="ts">
import { ProductAvailable } from '@/store/ksconnect';
import { computed, defineProps, toRefs } from 'vue';
import { useStore } from 'vuex';

const props = defineProps<{
    pid: number;
}>();

const emit = defineEmits<{
    (e: 'hide', value: any): void;
}>();

const store = useStore();
const { pid } = toRefs(props);

const compatibilityList = computed(() => {
    const ksProduct = store.getters['orders/products'].find((product: ProductAvailable) => product.pid == pid.value);
    return ksProduct ? ksProduct?.data?.products_compatibilities : [];
});

const currentWindowsVersion = computed(() => {
    return store.getters['ksconnect/deviceInfo']?.os_caption;
});

const hideDialog = () => {
    emit('hide', true);
};
</script>

<style lang="scss" scoped></style>
