<template>
    <div v-if="productIsWindows && windowsNeedsUpgrade">
        <WindowsUpgrade :pid="selectedPid"></WindowsUpgrade>
    </div>
    <div v-else>
        <span class="p-text-secondary text-lg block mb-5">Confirmare produs si cheie pentru activare</span>

        <div class="flex flex-column justify-content-center align-items-start">
            <div class="flex flex-row align-items-center justify-content-center cursor-pointer selected-product">
                <img width="100px" class="product-image" :src="selectedProduct?.image" alt="" />
                <div class="flex flex-column justify-content-center align-items-start">
                    <span class="font-semibold text-lg mb-2">{{ selectedProduct?.name }}</span>
                    <div class="flex flex-column justify-content-center align-items-start gap-2">
                        <ChecksProductCompatible :pid="selectedPid"></ChecksProductCompatible>
                        <ChecksProductInstalled :pid="selectedPid" v-if="!productTypeIsWindows"></ChecksProductInstalled>
                        <div
                            class="flex flex-row justify-content-start align-items-start gap-2"
                            :class="{
                                'text-green-500': !keyHasDeviceAssociated,
                                'text-orange-500': keyHasDeviceAssociated,
                            }"
                        >
                            <i
                                :class="{
                                    pi: true,
                                    'pi-check': !keyHasDeviceAssociated,
                                    'pi-exclamation-circle': keyHasDeviceAssociated,
                                }"
                            ></i>
                            <div class="flex flex-column justify-content-center align-items-start">
                                <div>{{ selectedKey?.key }}</div>
                                <ul class="m-0 pl-5">
                                    <li v-if="keyHasDeviceAssociated">
                                        Cheia de produs este asociata unui alt dispozitiv:
                                        <span class="font-semibold">{{ keyDevice }}</span>
                                    </li>
                                    <li v-else>Cheia nu este asociata unui alt dispozitiv</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ml-6 mt-4" v-if="!productTypeIsWindows">
                <div class="flex align-items-center cursor-">
                    <Checkbox v-model="uninstallActiveKeys" inputId="uninstallActiveKeys" name="uninstallActiveKeys" value="1" />
                    <label for="uninstallActiveKeys" class="ml-2 mr-1 cursor-pointer">
                        Dezinstaleaza inclusiv cheile de produs active pe dispozitiv inainte de activare
                        <span class="font-italic font-semibold">(recomandat)</span>.
                    </label>
                    <span class="font-semibold underline cursor-pointer" @click="openInfoDialog">Detalii</span>
                </div>
            </div>
            <InfoUninstallActiveKeysDialog @close="closeInfoDialog" v-if="isInfoDialogVisible"></InfoUninstallActiveKeysDialog>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import ChecksProductCompatible from '@/components/KSConnect/Checks/ProductCompatible.vue';
import ChecksProductInstalled from '@/components/KSConnect/Checks/ProductInstalled.vue';
import ProductInstaller from '../Checks/ProductInstaller.vue';
import InfoUninstallActiveKeysDialog from '@/components/KSConnect/Info/UninstallActiveKeysDialog.vue';
import WindowsUpgrade from '@/components/KSConnect/Checks/WindowsUpgrade.vue';
import { useKeySelected } from '@/composables/ksconnect/use-key-selected';
import { ProductReadyForActivation, ProductTypes } from '@/store/ksconnect';

const store = useStore();
import { useWindowsUpgrade } from '@/composables/ksconnect/use-windows-upgrade';
import { useInstaller } from '@/composables/ksconnect/use-installer';

const isInfoDialogVisible = ref<boolean>(false);

const openInfoDialog = () => (isInfoDialogVisible.value = true);

const closeInfoDialog = () => (isInfoDialogVisible.value = false);

const selectedPid = computed(() => store.getters['ksconnect/activationWizardSelectedPid']);
const selectedKeyId = computed(() => store.getters['ksconnect/activationWizardSelectedKey']);

const selectedProduct = computed<ProductReadyForActivation>(() => {
    return store.getters['ksconnect/productsReadyForActivation'].find((p: any) => p.pid === selectedPid.value);
});

const uninstallActiveKeys = computed({
    get: () => {
        return [store.getters['ksconnect/activationWizardUninstallActiveKeys']];
    },
    set: (value: any) => {
        // This fucking shit doesn't make any sens, but it's Primevue's fault
        if (Array.isArray(value)) {
            value = value.filter((v: any) => v);
            if (value.length == 0) {
                value = ['0', '0'];
            }
        }

        store.commit('ksconnect/SetUninstallActiveKeys', value[1]);
    },
});

const productTypeIsWindows = computed(() => {
    return selectedProduct.value.type === ProductTypes.WINDOWS;
});

const needsInstallation = computed(() => {
    return !isProductInstalled.value && !isProductInstalledWithOthers.value;
});

const { selectedKey, keyHasDeviceAssociated, keyDevice } = useKeySelected(selectedKeyId.value);
const { isProductInstalled, isProductInstalledWithOthers } = useInstaller(selectedPid.value);
const { windowsNeedsUpgrade, productIsWindows } = useWindowsUpgrade(selectedPid.value);
</script>

<style scoped>
.selected-product {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    .product-image {
        width: 160px;
    }
}
</style>
